import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled, css } from "@mui/system";
import { Modal as BaseModal } from "@mui/base/Modal";
import {
  Fade,
  Button,
  Box,
  Typography,
  InputLabel,
  Input,
  Divider,
  IconButton,
  FormControl,
  FormHelperText,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  verifyContinuity,
  aggregatePeriods,
} from "../../../utils/timePeriodsValidator";

export default function PeriodsModal({
  timeIntervals,
  power,
  openModal,
  handleClose,
  onUpdatePeriods,
}) {
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [newCoeff, setNewCoeff] = useState(false);
  const [newPeriod, setNewPeriod] = useState({
    start: "",
    end: "",
    coefficient: "",
  });
  const [periods, setPeriods] = useState([]);
  const [adjustedPower, setAdjustedPower] = useState("");
  const [errors, setErrors] = useState({});
  const [pendingSave, setPendingSave] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setOpen(openModal);
    setPeriods(timeIntervals);
    setAdjustedPower(power);
  }, [openModal, timeIntervals, power]);

  // Styles généraux pour les inputs
  const inputStyles = {
    background: theme.palette.primary.editableBackground,
    borderBottom: `2px solid ${theme.palette.primary.borderBottom}`,
    "&:before, &:after": { borderBottom: "none" },
  };

  // Validation du format HH:MM
  const validateTimeFormat = (value) =>
    /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/.test(value);

  // Gestion de la mise à jour des périodes
  const handleChange = (index, field, value) => {
    const updatedPeriods = [...periods];
    updatedPeriods[index][field] = value;

    if ((field === "start" || field === "end") && !validateTimeFormat(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [index]: {
          ...prevErrors[index],
          [field]: "Format incorrect, doit être HH:MM",
        },
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [index]: { ...prevErrors[index], [field]: null },
      }));
    }
    setPeriods(updatedPeriods);
  };

  // Ajouter une nouvelle période
  const addNewPeriod = () => {
    if (!newPeriod.start || !newPeriod.end || !newPeriod.coefficient) {
      console.error("La nouvelle période doit contenir des valeurs valides.");
      return;
    }

    setPeriods((prevPeriods) => {
      const updatedPeriods = [...prevPeriods, newPeriod];
      return aggregatePeriods(updatedPeriods);
    });

    // Réinitialisation des champs après ajout
    setNewPeriod({ start: "", end: "", coefficient: "" });
    setNewCoeff(false);
  };

  // Vérifie la continuité lors de l'enregistrement
  useEffect(() => {
    if (pendingSave) {
      const result = verifyContinuity(periods);
      if (!result) {
        setAlert(true);
      } else {
        setAlert(false);
        onUpdatePeriods(periods); // Envoie les périodes mises à jour au parent
        handleClose(); // Fermer la modale
      }
      setPendingSave(false);
    }
  }, [periods, pendingSave]);

  // Gestion du clic sur "Enregistrer"
  const handleSave = () => {
    if (newCoeff) {
      addNewPeriod();
    }
    setPendingSave(true);
  };

  // Gestion des changements dans le champ "coefficient"
  const handleNewPeriodChange = (field, value) => {
    if (field === "coefficient") {
      setNewPeriod((prev) => ({
        ...prev,
        [field]: parseFloat(value) || "", // Conversion en float
      }));
    } else {
      setNewPeriod((prev) => ({ ...prev, [field]: value }));
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: StyledBackdrop }}
    >
      <Fade in={open}>
        <ModalContent sx={style}>
          <Typography variant="h6" id="transition-modal-title" gutterBottom>
            Adjustment coefficient values
          </Typography>

          {alert && (
            <Alert severity="error">
              Votre saisie est incorrecte, veuillez vérifier et corriger
              celle-ci.
            </Alert>
          )}

          <ScrollableBox>
            {" "}
            <Box display="flex" justifyContent="space-between">
              {[
                "Début",
                "Fin",
                "Coefficient",
                "Puissance ajustée disponible",
              ].map((label, index) => (
                <InputLabel key={index} sx={{ marginRight: 2, flex: 3 }} shrink>
                  {label}
                </InputLabel>
              ))}
            </Box>
            {periods.map((element, index) => (
              <Box key={index} mb={4}>
                <Box display="flex" justifyContent="space-between">
                  <FormControl
                    fullWidth
                    error={!!errors[index]?.start}
                    sx={{ mr: 2 }}
                  >
                    <Input
                      id="start"
                      value={element.start ?? ""}
                      onChange={(e) =>
                        handleChange(index, "start", e.target.value)
                      }
                      sx={inputStyles}
                      placeholder="HH:MM"
                    />
                    <FormHelperText>{errors[index]?.start}</FormHelperText>
                  </FormControl>

                  <FormControl
                    fullWidth
                    error={!!errors[index]?.end}
                    sx={{ mr: 2 }}
                  >
                    <Input
                      id="end"
                      value={element.end ?? ""}
                      onChange={(e) =>
                        handleChange(index, "end", e.target.value)
                      }
                      sx={inputStyles}
                      placeholder="HH:MM"
                    />
                    <FormHelperText>{errors[index]?.end}</FormHelperText>
                  </FormControl>

                  <FormControl fullWidth sx={{ mr: 2 }}>
                    <Input
                      id="coefficient"
                      value={element.coefficient ?? ""}
                      onChange={(e) =>
                        handleChange(index, "coefficient", e.target.value)
                      }
                      sx={inputStyles}
                      placeholder="0.0"
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <Input
                      id="puissance"
                      value={adjustedPower ?? ""}
                      readOnly
                    />
                  </FormControl>
                </Box>
              </Box>
            ))}
          </ScrollableBox>

          <Divider />

          <Box display="flex" justifyContent="flex-end" my={2}>
            <IconButton
              onClick={() => setNewCoeff(true)}
              sx={{ color: theme.palette.primary.borderBottom }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Box>

          {newCoeff && (
            <Box mb={4} display="flex" justifyContent="space-between">
              <Box flex={1} sx={{ mr: 2 }}>
                <Input
                  id="start"
                  value={newPeriod.start ?? ""}
                  onChange={(e) =>
                    handleNewPeriodChange("start", e.target.value)
                  }
                  sx={inputStyles}
                  placeholder="HH:MM"
                />
              </Box>

              <Box flex={1} sx={{ mr: 2 }}>
                <Input
                  id="end"
                  value={newPeriod.end ?? ""}
                  onChange={(e) => handleNewPeriodChange("end", e.target.value)}
                  sx={inputStyles}
                  placeholder="HH:MM"
                />
              </Box>

              <Box flex={1} sx={{ mr: 2 }}>
                <Input
                  id="coefficient"
                  value={newPeriod.coefficient ?? ""}
                  onChange={(e) =>
                    handleNewPeriodChange(
                      "coefficient",
                      parseFloat(e.target.value)
                    )
                  }
                  onBlur={(e) =>
                    handleNewPeriodChange(
                      "coefficient",
                      parseFloat(e.target.value).toFixed(1)
                    )
                  }
                  sx={inputStyles}
                  placeholder="0.0"
                  type="number"
                  inputProps={{ step: 0.1 }}
                />
              </Box>

              <Box flex={1} />
            </Box>
          )}

          <Box display="flex" flexDirection="column" alignItems="center">
            <Button
              onClick={handleSave}
              sx={{ textTransform: "none" }}
              variant="contained"
            >
              Enregistrer les modifications
            </Button>
            <Button
              onClick={handleClose}
              sx={{ textTransform: "none" }}
              variant="text"
            >
              Annuler et fermer
            </Button>
          </Box>
        </ModalContent>
      </Fade>
    </Modal>
  );
}

PeriodsModal.propTypes = {
  timeIntervals: PropTypes.array.isRequired,
  openModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

// Backdrop Component
const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = { open: PropTypes.bool };

// Conteneur scrollable pour le contenu du modal
const ScrollableBox = styled(Box)`
  max-height: 400px;
  overflow-y: auto;
  padding-right: 16px;
`;

const grey = {
  50: "#F3F6F9",
  200: "#DAE2ED",
  700: "#434D5B",
  900: "#1C2025",
};

// Styled Modal
const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

// Modal Content Styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "80%",
};

const ModalContent = styled("div")(
  ({ theme }) => css`
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};
  `
);
