import "./App.css";
import { useContext } from "react";
import Main from "./components/pages/Main.js";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import GoogleSignIn from "./components/common/GoogleSignIn.js";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ProfileProvider, ProfileContext } from "./contexts/ProfileProvider";
import ErrorPage from "./components/pages/ErrorPage.js";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ProfileProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/pdls" />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/signin" element={<GoogleSignIn />} />
            <Route
              path="/*"
              element={
                <ProtectedRoute>
                  <Main />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/pdls" />} />
          </Routes>
        </Router>
      </ProfileProvider>
    </ThemeProvider>
  );
}

const ProtectedRoute = ({ children }) => {
  const { email, loading } = useContext(ProfileContext);

  if (loading) {
    return <div>Loading...</div>; // Show loading while checking token
  }

  return email ? children : <Navigate to="/signin" />;
};

export default App;
