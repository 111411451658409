import axios from "axios";

const createPdlPool = async (newData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/createPdlPoolView`,
      newData
    );
    const created = response.data;
    return created;
  } catch (error) {
    console.error("Error creating pdlpool data:", error);
    return [];
  }
};

const deletePdlPool = async (dataToDelete) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/deletePdlPoolView`,
      {
        data: dataToDelete,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = response.data;
    return result;
  } catch (error) {
    console.error("Error while deleting pdlpool data", error);
    throw error;
  }
};

export { createPdlPool, deletePdlPool };
