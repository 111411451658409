import React, { useEffect, useRef, useState, useCallback } from "react";
import { Box, Grid } from "@mui/material";
import AgGrid from "../common/aggrid/AgGrid";
import { getCustomer } from "../../services/customerService";
import StatusButtonRenderer from "../common/renderers/StatusButtonRenderer";
import CellActionsRenderer from "../common/renderers/CellActionsRenderer";
import InitilizationRenderer from "../common/renderers/InitilizationRenderer";
import DetailPanel from "./DetailPanel";
import PdlCellRenderer from "../common/renderers/PdlCellRenderer";
import { formatDate, dateComparator } from "../../utils/dateUtils";
import HoverRenderer from "../common/renderers/HoverRenderer";
import ClientRenderer from "../common/renderers/ClientRenderer";
import BooleanDropdownFilter from "../common/filters/BooleanDropdownFilter";

const Customer = () => {
  const gridRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const title = "clients";

  const columnDefs = [
    {
      headerName: "Client",
      field: "last_name",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: ClientRenderer,
      cellRendererParams: (params) => ({
        formattedText: `${
          params.data?.first_name
        } ${params.data?.last_name?.toUpperCase()}\n${params.data.id.replace(
          /-/g,
          " "
        )}`,
        list: "customer",
      }),
    },
    {
      headerName: "Active",
      field: "active",
      sortable: true,
      //filter: true,
      cellRenderer: StatusButtonRenderer,
      filter: BooleanDropdownFilter,
      filterParams: {
        trueLabel: "Activé",
        falseLabel: "Désactivé",
      },
      valueFormatter: (params) => (params.value ? "Activé" : "Désactivé"),
    },
    {
      headerName: "Etat initialisation",
      field: "etat_initialisation",
      sortable: false,
      cellRenderer: InitilizationRenderer,
    },
    {
      headerName: "Date création",
      field: "creation_date",
      sortable: false,
      filter: "agDateColumnFilter",
      valueFormatter: formatDate,
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          return dateComparator(filterLocalDateAtMidnight, cellValue);
        },
      },
    },
    {
      headerName: "Adresse",
      field: "address",
      sortable: false,
      cellRenderer: HoverRenderer,
      cellRendererParams: (params) => ({
        formattedText: params.data?.address || "",
      }),
    },
    {
      headerName: "Email",
      field: "email",
      sortable: false,
      cellRenderer: HoverRenderer,
      cellRendererParams: (params) => ({
        formattedText: params.data?.email || "",
      }),
    },
    {
      headerName: "Type",
      field: "type",
      sortable: false,
    },
    {
      headerName: "Pdls",
      field: "pdls",
      cellRenderer: (params) => (
        <PdlCellRenderer {...params} onPdlClick={handleRowClick} />
      ),
      filterValueGetter: (params) => params.data.pdlString,
      sortable: true,
      comparator: (valueA, valueB) => {
        console.log("Comparing: ", valueA, valueB);

        // Extract PDL numbers from the objects, handling cases where PDL might be null or undefined
        const pdlA = valueA[0]?.pdl || ""; // Default to an empty string if pdlA is null or undefined
        const pdlB = valueB[0]?.pdl || ""; // Default to an empty string if pdlB is null or undefined

        // Regular expression to check if the PDL is numeric
        const isNumeric = (pdl) => /^\d+$/.test(pdl);

        // Check if both PDLs are numeric
        if (isNumeric(pdlA) && isNumeric(pdlB)) {
          const numA = parseInt(pdlA, 10);
          const numB = parseInt(pdlB, 10);
          return numA - numB; // Sort numerically if both are numbers
        }

        // If one or both PDLs are not numeric or are alphanumeric, sort alphabetically
        return pdlA.localeCompare(pdlB);
      },
    },
    {
      headerName: "Nombre d'appareils",
      field: "nb_devices_total",
      sortable: false,
      cellStyle: { textAlign: "center" },
      cellRenderer: HoverRenderer,
      cellRendererParams: (params) => ({
        formattedText: params.data?.device_descriptions_total
          ? params.data.device_descriptions_total.split(", ").join("\n")
          : "",
      }),
    },
    {
      headerName: "",
      field: "actions",
      cellRenderer: CellActionsRenderer,
      cellClass: "sticky-action-column",
      width: 100,
      pinned: "right",
      cellRendererParams: {
        options: ["Consulter le détail", "Accèder au looker"],
        url: "detailCustomer",
        onUpdateRowData: (id, newStatut) => {
          setRowData((prevData) =>
            prevData.map((row) =>
              row.id === id ? { ...row, active: newStatut } : row
            )
          );
        },
      },
    },
  ];

  const handleUpdateCell = (updatedData) => {
    const updatedRowData = rowData.map((elm) =>
      elm.id === updatedData.detail.id ? updatedData.detail : elm
    );
    setRowData(updatedRowData);
  };

  const onGetCustomerData = useCallback(async () => {
    try {
      const token = localStorage.getItem("google_id_token");
      let data = await getCustomer(token);

      //console.log("/////////////////////customer id////////////////////:", data); //checking whats is passing here

      data.forEach((elm) => {
        elm.pdlString = elm.pdls?.length
          ? elm.pdls.map((pdlObj) => pdlObj.pdl).join(",")
          : "";
        elm.creation_date = elm.creation_date
          ? new Date(elm.creation_date)
          : null;
      });
      setRowData(data);
    } catch (error) {
      console.error("Failed to fetch Customer data:", error);
    }
  }, []);

  useEffect(() => {
    onGetCustomerData();
  }, [onGetCustomerData]);

  useEffect(() => {
    window.addEventListener("cellUpdate", handleUpdateCell);
    return () => {
      window.removeEventListener("cellUpdate", handleUpdateCell);
    };
  }, [rowData]);

  const handleRowClick = (event) => {
    if (event.data.pdls.length > 1) {
      setSelectedRowData(event.data);
      setShowDetails(true);
    }
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
    setSelectedRowData(null);
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={showDetails ? 8 : 12}>
          <AgGrid
            gridRef={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            title={title}
            search={"Rechercher un client, une adresse, un PDL"}
            filterColumns={["last_name", "address", "pdlString"]}
            gridId="customer-list"
          />
        </Grid>
        {showDetails && (
          <Grid item xs={4}>
            <DetailPanel
              data={selectedRowData}
              handleClose={handleCloseDetails}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Customer;
