import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CardHeader,
  useTheme,
  IconButton,
  Tooltip,
} from "@mui/material";
import { CheckmarkSwitchWithLabel } from "../../layout/CustomizedSwitch";
import CustomTooltip from "../../common/renderers/CustomTooltip";
import CustomTextField from "../../layout/CustomTextField";
import CodeRoundedIcon from "@mui/icons-material/CodeRounded";

const ProviderInfos = ({ providerData }) => {
  const theme = useTheme();
  const [isChecked, setIsChecked] = useState(true);
  const [openTooltip, setOpenTooltip] = useState(false);
  const mockProviderData = {
    provider: "ProviderCorp",
    credentials: "User123:Password",
    token: "abcd-1234-efgh-5678",
  };
  const handleSwitchChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleChange = () => {};

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#b8c494",
          color: theme.palette.primary.main,
          height: "50px",
        }}
        title="Informations du provider"
        titleTypographyProps={{ variant: "h6", fontSize: "0.875rem" }}
        action={
          <CustomTooltip title="Afficher l'interface de log 'additionnal data provider'">
            <IconButton aria-label="code" variant="circled">
              <CodeRoundedIcon
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: "1rem",
                }}
              />
            </IconButton>
          </CustomTooltip>
        }
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h7"
          component="div"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold" }}
        >
          Données en provenance du provider
        </Typography>

        <Box mt={2}>
          <CustomTextField
            fieldName="provider"
            value={mockProviderData["provider"]}
            inputLabel="Provider"
            onChange={(newValue, fieldName) =>
              handleChange(fieldName, newValue)
            }
            sx={{ marginRight: 2 }}
          />
        </Box>

        <Box mt={2}>
          <CustomTextField
            fieldName="credentials"
            value={mockProviderData["credentials"]}
            inputLabel="Credentials"
            onChange={(newValue, fieldName) =>
              handleChange(fieldName, newValue)
            }
            sx={{ marginRight: 2 }}
            readOnly={false}
          />
        </Box>
        <Box display="flex" mt={2} justifyContent="space-between">
          <CustomTextField
            fieldName="token"
            value={mockProviderData["token"]}
            inputLabel="Token"
            onChange={(newValue, fieldName) =>
              handleChange(fieldName, newValue)
            }
            sx={{ marginRight: 2, flex: 0.25 }}
          />
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          {/* Label */}
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", color: "#24395E" }}
          >
            Activation du Provider_Customer
          </Typography>

          {/* Switch with label */}
          <Box display="flex" alignItems="center">
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                color: theme.palette.primary.main,
                marginRight: "8px",
              }}
            >
              {isChecked ? "Activé" : "Désactivé"}
            </Typography>
            <CheckmarkSwitchWithLabel
              checked={isChecked}
              onChange={handleSwitchChange}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProviderInfos;
