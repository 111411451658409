import React, { useState } from "react";
import CustomTooltip from "./CustomTooltip";

const ClientRenderer = (props) => {
  const { formattedText, list } = props;
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const formattedValue = props.value ? props.value?.toUpperCase() : "";

  return (
    <span
      className="missionSpan"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      style={{
        position: "relative",
        display: "inline-block",
        textDecoration: "underline",
      }}
    >
      <CustomTooltip title={formattedText}>
        <a
          href={`/detailCustomer/${
            list == "customer" ? props.data?.id : props.data?.customer_id
          }`}
          style={{
            textDecoration: hover ? "underline" : "none",
            color: "black",
            cursor: "pointer",
          }}
        >
          {formattedValue}
        </a>
      </CustomTooltip>
    </span>
  );
};

export default ClientRenderer;
