const formatDate = ({ value }) => (value ? value.toLocaleDateString() : "");

const dateComparator = (filterLocalDateAtMidnight, cellValue) => {
  if (cellValue === null || cellValue === undefined) return -1;
  // Crée une nouvelle date sans les heures pour le filtrage
  const cellDate = new Date(cellValue.setHours(0, 0, 0, 0));
  const filterDate = new Date(filterLocalDateAtMidnight.setHours(0, 0, 0, 0));

  if (cellDate < filterDate) {
    return -1;
  } else if (cellDate > filterDate) {
    return 1;
  }
  return 0;
};

const parseDate = ({ newValue, oldValue }) => {
  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  const match = dateRegex.exec(newValue);
  if (match) {
    const [_, day, month, year] = match;
    const parsedDate = new Date(`${year}-${month}-${day}`);
    return !isNaN(parsedDate.getTime()) ? parsedDate.toISOString() : oldValue;
  }
  alert("Le format de la date doit être JJ/MM/AAAA");
  return oldValue;
};

const formatDateTime = ({ value }) =>
  value
    ? new Date(value).toLocaleString("fr-FR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      })
    : "";

const parseDateTime = ({ newValue, oldValue }) => {
  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})$/;
  const match = dateRegex.exec(newValue);
  if (match) {
    const [_, day, month, year, hours, minutes] = match;
    const parsedDate = new Date(
      `${year}-${month}-${day}T${hours}:${minutes}:00`
    );
    return !isNaN(parsedDate.getTime()) ? parsedDate.toISOString() : oldValue;
  }
  alert("Le format de la date doit être JJ/MM/AAAA HH:MM");
  return oldValue;
};

const getTime = (value) => {
  const date = new Date(value);
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

const getOnlyDate = (value) => {
  const date = new Date(value);
  return date.toLocaleDateString("fr-FR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export {
  formatDate,
  dateComparator,
  formatDateTime,
  parseDate,
  parseDateTime,
  getTime,
  getOnlyDate,
};
