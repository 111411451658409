import React from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/signin");
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          textAlign: "center",
          maxWidth: 400,
        }}
      >
        <Typography variant="h4" gutterBottom>
          Token non valide{" "}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Votre session a expiré ou le jeton n'est pas valide. Veuillez essayer
          de vous connecter à nouveau.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          onClick={handleGoBack}
        >
          Accéder à la connexion{" "}
        </Button>
      </Paper>
    </Box>
  );
};

export default ErrorPage;
