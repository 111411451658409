import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Link,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { Code } from "@mui/icons-material";

const Logs = ({ data }) => {
  const theme = useTheme();

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#40bcc4",
          color: theme.palette.primary.main,
          height: "50px",
        }}
        title="Informations logs de connexion"
        titleTypographyProps={{ variant: "h6", fontSize: "0.875rem" }}
      />
      <CardContent>
        <Paper
          elevation={2}
          sx={{
            p: 2,
            backgroundColor: theme.palette.primary.paperBackground,
            borderRadius: 2,
            mt: 2,
          }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            color={theme.palette.primary.main}
          >
            Afficher les derniers logs de connexion.
          </Typography>

          <Typography
            variant="body2"
            sx={{ mt: 1, color: theme.palette.primary.main }}
          >
            Affichage des logs par date, type, type de connexion, features,
            commandes, informations spécifiques au device, et informations
            additionnelles du provider.
          </Typography>

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            mt={2}
          >
            <Link
              href="#"
              underline="hover"
              sx={{
                color: theme.palette.primary.borderBottom,
                display: "flex",
                alignItems: "center",
              }}
            >
              Charger la donnée
              <Code sx={{ ml: 0.5 }} />
            </Link>
          </Box>
        </Paper>
      </CardContent>
    </Card>
  );
};

export default Logs;
