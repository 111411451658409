import React, { useState, useEffect } from "react";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import CustomTooltip from "./CustomTooltip";

const StatusButtonRenderer = (props) => {
  const [status, setStatus] = useState(props.value);

  useEffect(() => {
    setStatus(props.value);
  }, [props.value]);

  return (
    <span
      className="missionSpan"
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        height: "100%",
      }}
    >
      <CustomTooltip title={status ? "Actif" : "Inactif"}>
        <PowerSettingsNewIcon style={{ color: status ? "green" : "red" }} />
      </CustomTooltip>
    </span>
  );
};

export default StatusButtonRenderer;
