import React from "react";
import CircleIcon from "@mui/icons-material/Circle";

const InitilizationRenderer = (props) => {
  return (
    <span
      className="missionSpan"
      style={{ position: "relative", display: "inline-block" }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <CircleIcon
          style={{
            color: props.value === "initialization_done" ? "green" : "red",
            fontSize: "16px",
          }}
        />
        <span style={{ marginLeft: "8px" }}>
          {props.value === "initialization_done" ? "Done" : props.value}
        </span>
      </div>
    </span>
  );
};

export default InitilizationRenderer;
