import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ChecklistIcon from "@mui/icons-material/Checklist";
import PeopleIcon from "@mui/icons-material/People";
import DevicesIcon from "@mui/icons-material/Devices";
import NavBar from "./NavBar";
import {useTheme} from "@mui/material/styles";
import {Link} from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import AddAlertIcon from "@mui/icons-material/AddAlert";

const drawerWidth = 240;

const Menu = () => {
    const theme = useTheme();
    const menuElements = [
        // { text: "Home", icon: HomeIcon, link: "home" },
        {text: "Alerting WALL", icon: AddAlertIcon, link: "alerting-wall"},
        {text: "PDLs", icon: InboxIcon, link: "pdls"},
        {text: "Customers", icon: PeopleIcon, link: "customers"},
        {text: "Devices", icon: DevicesIcon, link: "devices"},
    ];

    return (
        <div>
            <NavBar/>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: drawerWidth,
                        boxSizing: "border-box",
                        backgroundColor: theme.palette.primary.main,
                    },
                }}
            >
                <Toolbar/>
                <Box sx={{overflow: "auto"}}>
                    <List>
                        {menuElements.map((element) => (
                            <ListItem key={element.text} disablePadding>
                                <ListItemButton component={Link} to={element.link}>
                                    <ListItemIcon sx={{color: "#FFFFFF"}}>
                                        <element.icon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={element.text}
                                        sx={{color: "#FFFFFF"}}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </div>
    );
};

export default Menu;
