import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  CardHeader,
  InputLabel,
  useTheme,
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import CircleIcon from "@mui/icons-material/Circle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "../../common/renderers/CustomTooltip";

const AssociatedDevices = ({ data, pdl_id }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const [devices, setDevices] = useState([]);
  const [nbDevices, setNbDevices] = useState(0);
  const [onDevicesCount, setOnDevicesCount] = useState(0);
  const [offDevicesCount, setOffDevicesCount] = useState(0);
  const [heatingPlanCount, setHeatingPlanCount] = useState(0);
  const [curtailmentCount, setCurtailmentCount] = useState(0);
  const [tooltipStates, setTooltipStates] = useState({});
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickToDevice = () => {
    // navigate(`/detailDevice/${}`);
  };

  const handleClickToDevices = () => {
    console.log("clické");
    navigate(`/devices?pdl_id=${pdl_id}`);
    // navigate(`/detailDevice/${}`);
  };

  useEffect(() => {
    if (data.devices) {
      setDevices(data.devices);
      setOnDevicesCount(data.devices.filter((device) => device.active).length);
      // Initialiser l'état des tooltips (chaque device a un état de tooltip fermé)
      const initialTooltipState = data.devices.reduce((acc, _, index) => {
        acc[index] = false;
        return acc;
      }, {});
      setTooltipStates(initialTooltipState);
      setOffDevicesCount(
        data.devices.filter((device) => !device.active).length
      );
      setHeatingPlanCount(
        data.devices.filter((device) => device.include_in_heating_plan).length
      );
      setCurtailmentCount(
        data.devices.filter((device) => device.include_in_curtailment).length
      );
    }
    if (data.devices_informations?.nb_devices) {
      setNbDevices(data.devices_informations.nb_devices);
    }
  }, [data]);

  // Copie l'ID du device dans le presse-papiers et affiche le tooltip
  const handleCopyToClipboard = (index) => {
    navigator.clipboard
      .writeText("text")
      .then(() => {
        setTooltipStates((prevState) => ({
          ...prevState,
          [index]: true,
        }));
        setTimeout(() => {
          setTooltipStates((prevState) => ({
            ...prevState,
            [index]: false,
          }));
        }, 1000);
        console.log(`Copied to clipboard for device at index ${index}`);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const getFormatedDate = (date) => {
    return date
      ? new Date(date)
          .toLocaleString("fr-FR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })
          .replace(",", "")
      : "JJ/MM/AAAA HH:MM";
  };

  const handleClickMoreOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#40bcc4",
          color: theme.palette.primary.main,
          height: "50px",
        }}
        title={"Liste des appareils associés (" + nbDevices + ")"}
        titleTypographyProps={{ variant: "h6", fontSize: "0.875rem" }}
        action={
          <CustomTooltip title="Accéder à la vue liste des appareils du PDL">
            <IconButton
              onClick={() => handleClickToDevices()}
              aria-label="refresh"
              variant="circled"
            >
              <BackupTableIcon
                sx={{ color: theme.palette.primary.main, fontSize: "1rem" }}
              />
            </IconButton>
          </CustomTooltip>
        }
      />
      <CardContent>
        <Card
          sx={{
            backgroundColor: "#e0f4f4",
            marginBottom: 2,
          }}
        >
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              color={theme.palette.primary.main}
              sx={{ fontWeight: "bold" }}
            >
              Vision globale
            </Typography>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 2 }}
            >
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                color={theme.palette.primary.main}
                sx={{ fontWeight: "bold" }}
              >
                Statut des appareil(s)
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                color={theme.palette.primary.main}
                sx={{ fontWeight: "normal" }}
              >
                {`${onDevicesCount} UP - ${offDevicesCount} OFF`}
              </Typography>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 2 }}
            >
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                color={theme.palette.primary.main}
                sx={{ fontWeight: "bold", flexGrow: 1 }}
              >
                Plan de programmation
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                color={theme.palette.primary.main}
                sx={{ fontWeight: "normal" }}
              >
                {heatingPlanCount}
              </Typography>
              <Box display="flex" alignItems="center" sx={{ ml: 1 }}>
                {heatingPlanCount ? (
                  <PlayCircleIcon style={{ color: "green" }} />
                ) : (
                  <StopCircleIcon style={{ color: "grey" }} />
                )}
              </Box>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                color={theme.palette.primary.main}
                sx={{ fontWeight: "bold", flexGrow: 1 }}
              >
                Plan d'effacement
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                color={theme.palette.primary.main}
                sx={{ fontWeight: "normal" }}
              >
                {curtailmentCount}
              </Typography>
              <Box display="flex" alignItems="center" sx={{ ml: 1 }}>
                {curtailmentCount ? (
                  <PlayCircleIcon style={{ color: "green" }} />
                ) : (
                  <StopCircleIcon style={{ color: "grey" }} />
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
        {devices.map((device, index) => (
          <React.Fragment key={index}>
            <Typography
              gutterBottom
              variant="h7"
              component="div"
              color={theme.palette.primary.main}
              sx={{ fontWeight: "bold", fontSize: "20px" }}
            >
              {device.name}
            </Typography>
            <Typography
              gutterBottom
              variant="h7"
              component="div"
              color={theme.palette.primary.main}
              sx={{ fontWeight: "bold" }}
            >
              {device.device_type}
            </Typography>
            <Box display="flex" justifyContent="space-between">
              {/* Contenu de la carte */}
              <Box flex={1}>
                <Card
                  sx={{
                    backgroundColor: "#f8f4f4",
                    marginBottom: 2,
                    flex: 1, // Pour permettre à la carte de prendre l'espace restant
                  }}
                >
                  <CardContent>
                    <Box display="flex" justifyContent="space-between">
                      <FormControl
                        variant="standard"
                        sx={{ marginRight: 2, flex: 1 }}
                      >
                        <InputLabel htmlFor={`active-${index}`} shrink>
                          Active
                        </InputLabel>
                        <Box
                          id={`active-${index}`}
                          display="flex"
                          alignItems="center"
                          sx={{ mt: 2 }}
                        >
                          <PowerSettingsNewIcon
                            style={{
                              color: device.active ? "green" : "red",
                            }}
                          />
                        </Box>
                      </FormControl>

                      <FormControl
                        variant="standard"
                        sx={{ marginRight: 2, flex: 1 }}
                      >
                        <InputLabel htmlFor={`online-${index}`} shrink>
                          Statut
                        </InputLabel>
                        <Box
                          id={`online-${index}`}
                          display="flex"
                          alignItems="center"
                          sx={{ mt: 2 }}
                        >
                          <CircleIcon
                            style={{
                              color: device.online ? "green" : "red",
                              fontSize: "16px",
                            }}
                          />
                          <span style={{ marginLeft: "8px" }}>
                            {device.online ? "OK" : "KO"}
                          </span>
                        </Box>
                      </FormControl>

                      <FormControl
                        variant="standard"
                        sx={{ marginRight: 2, flex: 1 }}
                      >
                        <InputLabel htmlFor={`heating-plan-${index}`} shrink>
                          P. Chauffe
                        </InputLabel>
                        <Box
                          id={`heating-plan-${index}`}
                          display="flex"
                          alignItems="center"
                          sx={{ mt: 2 }}
                        >
                          {device.include_in_heating_plan ? (
                            <PlayCircleIcon style={{ color: "green" }} />
                          ) : (
                            <StopCircleIcon style={{ color: "grey" }} />
                          )}
                        </Box>
                      </FormControl>

                      <FormControl
                        variant="standard"
                        sx={{ marginRight: 2, flex: 1 }}
                      >
                        <InputLabel
                          htmlFor={`curtailment-plan-${index}`}
                          shrink
                        >
                          P. Effacement
                        </InputLabel>
                        <Box
                          id={`curtailment-plan-${index}`}
                          display="flex"
                          alignItems="center"
                          sx={{ mt: 2 }}
                        >
                          {device.include_in_curtailment ? (
                            <PlayCircleIcon style={{ color: "green" }} />
                          ) : (
                            <StopCircleIcon style={{ color: "grey" }} />
                          )}
                        </Box>
                      </FormControl>
                    </Box>

                    <Box sx={{ mt: 5 }} />
                    <Box display="flex" justifyContent="space-between">
                      <InputLabel
                        htmlFor={`last-status-change-${index}`}
                        shrink
                      >
                        Dernier changement d'état
                      </InputLabel>
                      <Typography
                        gutterBottom
                        variant="h7"
                        component="div"
                        color={theme.palette.primary.main}
                        sx={{ fontSize: "small" }}
                      >
                        {getFormatedDate(device["last_status_change_date"])}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <InputLabel
                        htmlFor={`last-status-change-${index}`}
                        shrink
                      >
                        Nom du produit
                      </InputLabel>
                      <Typography
                        gutterBottom
                        variant="h7"
                        component="div"
                        color={theme.palette.primary.main}
                        sx={{ fontSize: "small" }}
                      >
                        {device["product_name"]}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>

              {/* Icônes email et more options en haut */}
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <CustomTooltip title="UUID/Id-PK copié !">
                  <IconButton
                    aria-label="email"
                    sx={{
                      padding: "0",
                      marginLeft: "16px",
                    }}
                    onClick={() => handleCopyToClipboard(index)}
                  >
                    <AlternateEmailIcon
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: "1rem",
                      }}
                    />
                  </IconButton>
                </CustomTooltip>

                <IconButton
                  aria-label="more options"
                  sx={{
                    padding: "0",
                    marginLeft: "16px",
                    marginTop: "8px",
                  }}
                  onClick={handleClickMoreOptions} // Ici, on appelle correctement la fonction
                >
                  <MoreVertRoundedIcon
                    sx={{ color: theme.palette.primary.main, fontSize: "1rem" }}
                  />
                </IconButton>

                {/* Menu qui s'affiche lors du clic */}
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {/* Options du menu */}
                  <MenuItem onClick={handleClickToDevice}>
                    Accèder au détail device
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    Activer/Désactiver le device
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </React.Fragment>
        ))}
      </CardContent>
    </Card>
  );
};

export default AssociatedDevices;
