import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Menu from "../layout/Menu";
import { Box } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import PDL from "./PDL";
import Devices from "./Devices";
import Customer from "./Customer";
import Home from "./Home";
import AlertingWall from "./AlertingWall";
import DetailPdl from "./Pdl/DetailPdl";
import DetailDevice from "./device/DetailDevice";
import DetailCustomer from "./Customer/DetailCustomer";

const Main = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Menu />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/alerting-wall" element={<AlertingWall />} />
          <Route path="/customers" element={<Customer />} />
          <Route path="/pdls" element={<PDL />} />
          <Route path="/devices" element={<Devices />} />
          <Route path="/detailpdl/:id" element={<DetailPdl />} />
          <Route path="/detaildevice/:id" element={<DetailDevice />} />
          <Route path="/detailCustomer/:customerId" element={<DetailCustomer />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default Main;
