import React from "react";
import {
  Grid,
  CardHeader,
  useTheme,
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Divider,
  CircularProgress,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CircleIcon from "@mui/icons-material/Circle";
import CustomTextField from "../../layout/CustomTextField";

const InformationPdl = ({ pdlData }) => {
  const theme = useTheme();

  if (!pdlData) {
    return (
      <Card>
        <CardHeader
          sx={{
            backgroundColor: "#b3c48dff",
            color: theme.palette.primary.main,
            height: "50px",
          }}
          title="Informations PDL"
          titleTypographyProps={{ variant: "h6", fontSize: "0.875rem" }}
        />
        <CardContent>
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
          <Typography variant="body2" align="center" sx={{ mt: 2 }}>
            Loading data...
          </Typography>
        </CardContent>
      </Card>
    );
  }

  // Group PDLs by client (assuming there's a client identifier in the data)
  const groupedPdls = pdlData.reduce((acc, pdl) => {
    const clientId = pdl.clientId || "default"; // Use a default key if clientId is not present
    if (!acc[clientId]) {
      acc[clientId] = [];
    }
    acc[clientId].push(pdl);
    return acc;
  }, {});

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#b3c48dff",
          color: theme.palette.primary.main,
          height: "50px",
        }}
        title="Informations PDL"
        titleTypographyProps={{ variant: "h6", fontSize: "0.875rem" }}
      />
      <CardContent>
        {Object.entries(groupedPdls).map(
          ([clientId, clientPdls], clientIndex) => (
            <Box key={clientId} sx={{ marginBottom: "20px" }}>
              {clientPdls.map((pdl, pdlIndex) => (
                <Box key={pdl.pdl || pdlIndex}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    sx={{ marginY: "10px" }}
                  >
                    <Grid item xs={4}>
                      <CustomTextField
                        inputLabel="PDL"
                        value={pdl.pdl || "N/A"}
                        readOnly={true}
                        sx={{
                          flex: 1,
                          borderBottom: 2,
                          borderColor: theme.palette.primary.main,
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CustomTextField
                        inputLabel="Nbre d'appareil"
                        value={pdl.nombre_appareils || "N/A"}
                        readOnly={true}
                        sx={{
                          flex: 1,
                          borderBottom: 2,
                          borderColor: theme.palette.primary.main,
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <CustomTextField
                        inputLabel="IncludePlan"
                        IconToShow={
                          <CircleIcon
                            sx={{
                              color: "green",
                              fontSize: "16px",
                              marginRight: "5px",
                            }}
                          />
                        }
                        value={pdl.include_plan ? "ALL" : ""}
                        readOnly={false}
                        sx={{
                          flex: 1,
                          borderColor: theme.palette.primary.main,
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton>
                        <VisibilityIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  {pdlIndex < clientPdls.length - 1 && (
                    <Divider sx={{ marginY: "10px" }} />
                  )}
                </Box>
              ))}
              {clientIndex < Object.keys(groupedPdls).length - 1 && (
                <Divider sx={{ marginY: "20px" }} />
              )}
            </Box>
          )
        )}
      </CardContent>
    </Card>
  );
};

export default InformationPdl;
