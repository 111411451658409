import GeneralInfos from "./GeneralInfos";
import InformationsAppareils from "./InformationsAppareils";
import InformationContrats from "./InformationContrats";
import ListProviders from "./ListProvider";
import InformationPdl from "./InformationPdl";
import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Select,
  TextField,
  Typography,
  useTheme,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCustomerScreen,
  updateActiveCustomer,
} from "../../../services/customerService";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RefreshIcon from "@mui/icons-material/Refresh";
import { formatToPdlNumber } from "../../../utils/numberUtils";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";

const DetailCustomer = () => {
  const { customerId } = useParams(); // Get id from URL params
  const [customerData, setCustomerData] = useState({});
  const [pdlDescription, setPdlDescription] = useState({});
  const theme = useTheme();
  const navigate = useNavigate();
  const [activePdl, setActivePdl] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const fetchCustomerData = useCallback(async (customerId) => {
    try {
      const customer = await getCustomerScreen(customerId);
      setCustomerData(customer || {});
      setActivePdl(customer.informations_generales.etat_d_inscription);
      console.log("Fetched customer data:", customer);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  }, []);

  useEffect(() => {
    if (customerId) {
      fetchCustomerData(customerId);
    }
  }, [customerId, fetchCustomerData]);

  ////console.log("Current customerData:", customerData);

  const handleChange = (event) => {
    const selectedPdl = event.target.value;
    setPdlDescription(selectedPdl);
    navigate(`/detailpdl/${selectedPdl.pdl_id}`);
  };

  const handleActivationChange = async (newStatus) => {
    console.log("Activation change requested:", newStatus);
    try {
      const result = await updateActiveCustomer(customerId, newStatus);
      if (result) {
        console.log("Successfully updated customer activation status");
        setActivePdl(newStatus); // Update the state in DetailCustomer
      }
    } catch (error) {
      console.error(
        "An error occurred while updating customer status: ",
        error
      );
    }
  };
  const handlePlayStopToggle = () => {
    setIsPlaying((prev) => !prev);
  };
  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3, backgroundColor: "#f3f3f3" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              //justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                sx={{
                  mr: 2,
                  height: "55px",
                  padding: "8px 16px",
                  fontSize: "20px",
                  borderColor: theme.palette.primary.main,
                  borderRadius: "25px",
                }}
              >
                {`${
                  customerData?.informations_generales?.nom?.toUpperCase() || ""
                }`}
              </Button>

              {customerData.informations_pdls &&
              customerData.informations_pdls.length > 0 ? (
                customerData.informations_pdls.length === 1 ? (
                  <TextField
                    variant="outlined"
                    value={
                      formatToPdlNumber(
                        customerData.informations_pdls[0]?.pdl
                      ) || "N/A"
                    }
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handlePlayStopToggle}
                            sx={{
                              color: isPlaying ? "#dc3545" : "#28a745", // Red for play, green for stop
                              backgroundColor: "white", // White background for inverted colors
                              border: `2px solid ${
                                isPlaying ? "#dc3545" : "#28a745"
                              }`, // Red border for play, green for stop
                              borderRadius: "50%", // Circular shape
                              width: "32px", // Button size
                              height: "32px",
                              padding: "6px",
                              "&:hover": {
                                backgroundColor: isPlaying
                                  ? "#f9e6e8"
                                  : "#e6f4e9", // Light red on play, light green on stop
                              },
                            }}
                          >
                            {isPlaying ? <StopIcon /> : <PlayArrowIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "white",
                        width: "300px",
                        color: theme.palette.primary.main,
                        border: `2px solid ${theme.palette.primary.main}`,
                        borderRadius: "25px",
                        height: "55px",
                        "& .MuiOutlinedInput-input": {
                          textAlign: "center",
                          padding: "0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      },
                    }}
                  />
                ) : (
                  <Select
                    value={pdlDescription.pdl_name_nbdevices || ""}
                    onChange={handleChange}
                    IconComponent={ArrowDropDownIcon}
                    displayEmpty
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handlePlayStopToggle}
                          sx={{
                            color: isPlaying ? "#dc3545" : "#28a745", // Red for play, green for stop
                            backgroundColor: "white",
                            border: `2px solid ${
                              isPlaying ? "#dc3545" : "#28a745"
                            }`, // Red border for play, green for stop
                            borderRadius: "50%",
                            width: "32px",
                            height: "32px",
                            padding: "6px",
                            "&:hover": {
                              backgroundColor: isPlaying
                                ? "#f9e6e8"
                                : "#e6f4e9", // Light red on play, light green on stop on hover
                            },
                          }}
                        >
                          {isPlaying ? <StopIcon /> : <PlayArrowIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={{
                      mr: 2,
                      width: "auto",
                      border: `2px solid ${theme.palette.primary.main}`,
                      borderRadius: "25px",
                      height: "55px",
                      ".MuiSelect-icon": { color: "#24395eff" },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    <option value="" disabled></option>
                    {customerData.informations_pdls.map((pdl, index) => (
                      <option key={index} value={pdl.pdl}>
                        {`PDL: ${pdl.pdl}`}
                      </option>
                    ))}
                  </Select>
                )
              ) : null}
            </Box>
            <Box display="flex" alignItems="center" sx={{ marginLeft: "auto" }}>
              {/* For the updated time, the date and time you see, is the refresh time
              and not the PDL update time, because the customer API
              doesn't retun any update time*/}
              <Typography sx={{ mr: 2, color: theme.palette.primary.main }}>
                Dernière mise à jour le {new Date().toLocaleDateString("fr-FR")}{" "}
                à {new Date().toLocaleTimeString("fr-FR")} {/**/}
              </Typography>
              <RefreshIcon
                onClick={() => window.location.reload()}
                style={{ cursor: "pointer" }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Typography
                sx={{ fontSize: "24px", color: theme.palette.primary.main }}
              >
                <Typography
                  component="span"
                  sx={{ fontSize: "24px", fontWeight: "bold" }}
                >
                  {customerData.informations_generales?.nom?.toUpperCase() ||
                    ""}
                </Typography>{" "}
                {customerData.informations_generales?.prenom
                  ? `${customerData.informations_generales?.prenom
                      .charAt(0)
                      .toUpperCase()}${customerData.informations_generales?.prenom
                      .slice(1)
                      .toLowerCase()}`
                  : ""}
              </Typography>
              <Box display="flex" alignItems="center" sx={{ ml: 2 }}>
                <Typography sx={{ color: theme.palette.primary.main, mr: 1 }}>
                  {activePdl ? "Activé" : "Désactivé"}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    border: `2px solid ${activePdl ? "green" : "red"}`,
                  }}
                >
                  <PowerSettingsNewIcon
                    style={{ color: activePdl ? "green" : "red" }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Box sx={{ mt: 2 }} />

        <Grid item xs={12}>
          <Box sx={{ display: "flex", gap: 2, height: "100%" }}>
            <Box sx={{ flex: 1, minHeight: "100%" }}>
              <GeneralInfos
                pdl_id={customerId}
                customerData={customerData}
                isActive={activePdl}
                onActivationChange={handleActivationChange}
              />
            </Box>
            <Box sx={{ flex: 1, minHeight: "100%" }}>
              <InformationPdl pdlData={customerData?.informations_pdls} />
              <Box sx={{ height: 16 }} />
              <InformationContrats
                contractsData={customerData?.informations_contrats}
              />
            </Box>
            <Box sx={{ flex: 1, minHeight: "100%" }}>
              <InformationsAppareils
                appData={customerData?.informations_appareils}
              />
              <Box sx={{ height: 16 }} />
              <ListProviders
                provideData={customerData?.informations_fournisseurs}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailCustomer;
