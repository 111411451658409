import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CardHeader,
  Button,
  InputLabel,
  useTheme,
  Chip,
  IconButton,
} from "@mui/material";
import CustomTextField from "../../layout/CustomTextField";
import { generateAggregations } from "../../../utils/timePeriodsValidator";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import PeriodsModal from "../../common/modals/PeriodsModal";
import KeyboardCommandKeyRoundedIcon from "@mui/icons-material/KeyboardCommandKeyRounded";

const Effacement = ({ data }) => {
  const theme = useTheme();
  const [timeIntervals, setTimeIntervals] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const initializeTimeIntervals = () => {
      setTimeIntervals(
        generateAggregations(data.hourly_adjustment_coefficient_values)
      );
    };
    if (data?.hourly_adjustment_coefficient_values) {
      initializeTimeIntervals();
    }
  }, [data]);

  const handleOpen = () => setOpen(true);

  const getColor = (coefficient) => {
    if (coefficient >= 0.8) {
      return "success";
    } else if (coefficient < 0.8 && coefficient >= 0.6) {
      return "warning";
    }
    return "error";
  };

  const handleUpdatePeriods = (updatedPeriods) => {
    setTimeIntervals(updatedPeriods);
    console.log("2 Périodes mises à jour : ", updatedPeriods);
  };

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#98c4a4",
          color: theme.palette.primary.main,
          height: "50px",
        }}
        title="Informations effacement"
        titleTypographyProps={{ variant: "h6", fontSize: "0.875rem" }}
        action={
          <IconButton aria-label="refresh" variant="circled">
            <KeyboardCommandKeyRoundedIcon
              sx={{ color: theme.palette.primary.main, fontSize: "1rem" }}
            />
          </IconButton>
        }
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h7"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold", marginBottom: theme.spacing(2) }}
        >
          Puissances réelles
        </Typography>
        <CustomTextField
          id="effacement-power"
          value={data["max_subscribed_power"] ?? ""}
          inputLabel="Puissance disponible totale (kW)"
          sx={{}}
        />
        <Box sx={{ marginTop: theme.spacing(4) }}>
          <Box display="flex" justifyContent="space-between">
            <InputLabel
              sx={{ marginTop: "15px" }}
              htmlFor="effacement-coefficients"
              shrink
            >
              Coefficients d'ajustements horaires
            </InputLabel>
            <Button
              onClick={handleOpen}
              sx={{
                textTransform: "none",
                color: theme.palette.primary.borderBottom,
              }}
              variant="text"
              endIcon={<BorderColorIcon />}
            >
              Modifier
            </Button>
            <PeriodsModal
              timeIntervals={timeIntervals}
              power={data["max_subscribed_power"]}
              openModal={open}
              handleClose={() => setOpen(false)}
              onUpdatePeriods={handleUpdatePeriods}
            />{" "}
          </Box>
          <Card
            sx={{
              backgroundColor: "#f8f4f4",
              marginTop: theme.spacing(2),
            }}
          >
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="left"
                mb={2}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ textAlign: "left", flex: 1 }}
                >
                  Début
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ textAlign: "left", flex: 1 }}
                >
                  Fin
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ textAlign: "left", flex: 1 }}
                >
                  Coefficient
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ textAlign: "center", flex: 2 }}
                >
                  Puissance ajustée disponible (kW)
                </Typography>
              </Box>

              {timeIntervals.map((element, index) => (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="left"
                  mb={2}
                  key={index}
                >
                  <Typography
                    variant="caption"
                    sx={{ textAlign: "left", flex: 1 }}
                  >
                    {element.start}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ textAlign: "left", flex: 1 }}
                  >
                    {element.end}
                  </Typography>
                  <Box
                    sx={{ flex: 1, display: "flex", justifyContent: "left" }}
                  >
                    <Chip
                      label={element.coefficient}
                      color={getColor(element.coefficient)}
                      sx={{
                        width: "50%",
                        textAlign: "left",
                      }}
                    />
                  </Box>
                  <Typography
                    variant="caption"
                    sx={{ textAlign: "center", flex: 2 }}
                  >
                    {data["max_subscribed_power"]}
                  </Typography>
                </Box>
              ))}
            </CardContent>
          </Card>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Effacement;
