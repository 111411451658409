import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardHeader,
  IconButton,
  Box,
  CircularProgress,
  useTheme,
  Tooltip,
} from "@mui/material";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const InformationAppareils = ({ appData }) => {
  const theme = useTheme();
  const navigate = useNavigate(); // Initialize useNavigate

  // Check if appData is undefined or null
  if (!appData || appData.length === 0) {
    return (
      <Card>
        <CardHeader
          sx={{
            backgroundColor: "#81ccc4ff",
            color: theme.palette.primary.main,
            height: "50px",
          }}
          title="Informations appareils (0)"
          titleTypographyProps={{ variant: "h6", fontSize: "0.875rem" }}
          action={
            <Tooltip title="Accéder à la vue liste des appareils du PDL" arrow>
              <IconButton>
                <ListAltIcon
                  sx={{ color: theme.palette.primary.main, fontSize: "1rem" }}
                />
              </IconButton>
            </Tooltip>
          }
        />
        <CardContent>
          <Box display="flex" alignItems="center">
            <CircularProgress />
          </Box>
          <Typography variant="body2" align="center" sx={{ mt: 2 }}>
            Loading data...
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const totalAppareils =
    (appData.nombre_par_statut?.online || 0) +
    (appData.nombre_par_statut?.offline || 0); // Calculate all the devices

  // Function to handle navigation
  const handleNavigate = () => {
    window.open(
      "https://media1.tenor.com/m/rrOtFDMCatQAAAAC/johnny-english-johnnyenglish.gif",
      "_blank"
    ); // Open dummy page
    //navigate( '/xxxxx');
  };

  // Once appData is available, render the data
  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#81ccc4ff",
          color: theme.palette.primary.main,
          height: "50px",
        }}
        title={`Informations appareils (${totalAppareils})`}
        titleTypographyProps={{ variant: "h6", fontSize: "0.8rem" }}
        action={
          <Tooltip title="Accéder à la vue liste des appareils du PDL" arrow>
            <IconButton size="large" variant="circled" onClick={handleNavigate}>
              <ListAltIcon
                sx={{ color: theme.palette.primary.main, fontSize: "1rem" }}
              />
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent>
        <Typography
          variant="h7"
          component="div"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold" }}
        >
          Nombre d'appareils par statut
        </Typography>

        {/* Display status information */}
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Typography variant="body2">Appareil(s) "online"</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="green" align="right">
              {appData.nombre_par_statut?.online
                ? appData.nombre_par_statut.online.toString().padStart(2, "0")
                : "--"}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body2">Appareil(s) "offline"</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="red" align="right">
              {appData.nombre_par_statut?.offline
                ? appData.nombre_par_statut.offline.toString().padStart(2, "0")
                : "--"}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, mb: 1 }}>
          <Typography
            variant="subtitle1"
            component="div"
            color={theme.palette.primary.main}
            sx={{ fontWeight: "bold" }}
          >
            Nombre d'appareils par type
          </Typography>
        </Box>
        {/* Display device types and their counts */}
        <Grid container spacing={1}>
          {appData.nombre_par_type &&
          Object.keys(appData.nombre_par_type).length > 0 ? (
            Object.keys(appData.nombre_par_type).map((typeKey, index) => (
              <React.Fragment key={index}>
                <Grid item xs={8}>
                  <Typography variant="body2">{typeKey}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    color={theme.palette.primary.main}
                    align="right"
                  >
                    {appData.nombre_par_type[typeKey]}
                  </Typography>
                </Grid>
              </React.Fragment>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" align="center">
                No device types available.
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default InformationAppareils;
