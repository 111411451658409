import React from "react";
import {
  Grid,
  CardHeader,
  useTheme,
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import LaunchIcon from "@mui/icons-material/Launch";
import CustomTextField from "../../layout/CustomTextField";

const InformationContracts = ({ contractsData }) => {
  const theme = useTheme();
  const handleRedirect = () => {
    window.open(
      "https://media1.tenor.com/m/7vsZ0yXbFUMAAAAC/despicable-me-minions.gif",
      "_blank"
    ); // Open dummy page
    //navigate('/device-info'); //To replace wiht the root Device Info
  };

  // Check if contractsData is undefined or null and display a loading spinner
  if (!contractsData) {
    return (
      <Card>
        <CardHeader
          sx={{
            backgroundColor: "#93c2a4ff",
            color: theme.palette.primary.main,
            height: "50px",
          }}
          title="Informations contract(s)"
          titleTypographyProps={{ variant: "h6", fontSize: "0.875rem" }}
        />
        <CardContent>
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
          <Typography variant="body2" align="center" sx={{ mt: 2 }}>
            Loading data...
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#93c2a4ff",
          color: theme.palette.primary.main,
          height: "50px",
        }}
        title="Informations contract(s)"
        titleTypographyProps={{ variant: "h6", fontSize: "0.8rem" }}
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h7"
          component="div"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold" }}
        >
          Contrat en cours
        </Typography>

        {contractsData.length > 0 ? (
          contractsData.map((contract, index) => (
            <Box key={index}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ marginY: "5px", display: "flex", flexWrap: "nowrap" }}
              >
                <Grid item xs={4}>
                  <CustomTextField
                    inputLabel="Apporteur"
                    value={contract.apporteur_name || "N/A"}
                    readOnly={true}
                    sx={{
                      flex: 1,
                      borderBottom: 2,
                      borderColor: theme.palette.primary.main,
                    }}
                  />
                </Grid>

                {/* Installateur */}
                <Grid item xs={4}>
                  <CustomTextField
                    inputLabel="Installateur"
                    value={contract.installateur_name || "N/A"}
                    readOnly={true}
                    sx={{
                      flex: 1,
                      borderBottom: 2,
                      borderColor: theme.palette.primary.main,
                    }}
                  />
                </Grid>

                {/* Date souscription */}
                <Grid item xs={4}>
                  <CustomTextField
                    inputLabel="Date souscription"
                    value={
                      new Date(contract.date_signature).toLocaleDateString(
                        "fr-FR"
                      ) || "N/A"
                    }
                    readOnly={true}
                    sx={{
                      flex: 1,
                      borderBottom: 2,
                      borderColor: theme.palette.primary.main,
                    }}
                  />
                </Grid>

                {/* Statut */}
                <Grid item xs={4}>
                  <CustomTextField
                    inputLabel="Status" // Change label to "Status"
                    IconToShow={
                      <CircleIcon
                        sx={{
                          color: contract.status === "Actif" ? "green" : "red", // Green if 'Actif', red otherwise
                          fontSize: "16px",
                          marginRight: "5px",
                        }}
                      />
                    }
                    value={contract.status || "N/A"} // Display contract status or "N/A" if not available
                    readOnly={false} // Set to true if you want it to be read-only
                    sx={{
                      flex: 1,
                      borderColor: theme.palette.primary.main,
                    }}
                  />
                </Grid>

                {/* Edit Icon */}
                <Grid item xs={1}>
                  <Tooltip title="Accéder à l'interface Appsheet PRM" arrow>
                    <IconButton>
                      <LaunchIcon
                        sx={{
                          //padding: "0",
                          //marginLeft: "16px",
                          alignItems: "right",
                        }}
                        onClick={handleRedirect}
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>

              {/* Divider between contract entries */}
            </Box>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary" align="center">
            No contract data available.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default InformationContracts;
