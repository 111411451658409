import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const SelectField = ({
  label,
  id,
  options,
  value,
  onChange,
  multiple = false,
  readOnly = true,
  sx,
}) => {
  const [openSelect, setOpenSelect] = useState(false);
  const theme = useTheme();

  return (
    <FormControl
      variant="outlined"
      sx={{ ...sx, marginRight: 2, flex: 1, top: "3px" }}
    >
      <InputLabel
        htmlFor={id}
        shrink
        sx={{
          // Apply explicit styling for consistent label
          transform: "none !important",
          fontSize: "smaller !important",
          position: "static !important",
          fontFamily: `${theme.typography.body1.fontFamily} !important`,
          lineHeight: `${theme.typography.body1.lineHeight} !important`,
          fontWeight: `${theme.typography.body1.fontWeight} !important`,
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        multiple={multiple}
        open={openSelect}
        onClose={() => setOpenSelect(false)}
        onOpen={() => setOpenSelect(true)}
        value={value ?? ""}
        onChange={onChange}
        sx={{
          color: theme.palette.primary.main,
          fontWeight: 560,
          height: "35px",
          "& fieldset": {
            border: "none", // This removes the border around the select component
          },
          borderBottom: !readOnly
            ? `2px solid ${theme.palette.primary.borderBottom}`
            : "",
          background: !readOnly ? theme.palette.primary.editableBackground : "",
        }}
      >
        {options.map((option) => (
          <MenuItem
            sx={{
              color: theme.palette.primary.main,
            }}
            key={option}
            value={option}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
