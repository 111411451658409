import React from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import Tooltip from "@mui/material/Tooltip";

const StopPlayRenderer = (props) => {
  return (
    <span
      className="missionSpan"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Tooltip>
        {props.value ? (
          <PlayCircleIcon style={{ color: "green" }} />
        ) : (
          <StopCircleIcon style={{ color: "grey" }} />
        )}
      </Tooltip>
    </span>
  );
};

export default StopPlayRenderer;
