import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";
import CustomTextField from "../../layout/CustomTextField";

const Effacement = ({ data }) => {
  const theme = useTheme();

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#40bcc4",
          color: theme.palette.primary.main,
          height: "50px",
        }}
        title="Informations techniques"
        titleTypographyProps={{ variant: "h6", fontSize: "0.875rem" }}
      />
      <CardContent>
        {/* First Field */}
        <Box>
          <CustomTextField
            fieldName="maxTheorique"
            value={"1.6"}
            inputLabel={
              <>
                Puiss. Max. Théorique{" "}
                <span style={{ fontWeight: "bold" }}>(kW)</span>
              </>
            }
            readOnly={false}
            fullWidth={true}
            sx={{
              marginRight: 2,
            }}
          />
        </Box>

        <Box mt={2}>
          {/* Second Field */}
          <CustomTextField
            fieldName="maxReelle"
            value={"1.6"}
            inputLabel={
              <>
                Puiss. Max. Réelle{" "}
                <span style={{ fontWeight: "bold" }}>(kW)</span>
              </>
            }
            readOnly={false}
            fullWidth={true}
            sx={{
              marginRight: 2,
            }}
          />
        </Box>
        <Box mt={2}>
          {/* Third Field */}
          <CustomTextField
            fieldName="maxStopTime"
            value={"0.65"}
            inputLabel={
              <>
                Temps d'arrêt max{" "}
                <span style={{ fontWeight: "bold" }}>(kW)</span>
              </>
            }
            readOnly={false}
            fullWidth={true}
            sx={{
              marginRight: 2,
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default Effacement;
