import { InputLabel, Input, FormControl, FormHelperText } from "@mui/material";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import ErrorIcon from "@mui/icons-material/Error";

const DateTimeField = ({
  fieldName,
  value: initialValue,
  inputLabel,
  readOnly = true,
  onChange,
  customizedColor = false,
}) => {
  const [value, setValue] = useState(initialValue ?? "");
  const [error, setError] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setValue(initialValue ?? "");
  }, [initialValue]);

  // Fonction pour formater la date vers JJ/MM/AAAA HH:MM
  const formatDateTime = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return `${String(date.getDate()).padStart(2, "0")}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${date.getFullYear()} ${String(
      date.getHours()
    ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
  };

  const validateDate = (dateTimeString) => {
    // Expression régulière pour valider le format JJ/MM/AAAA HH:MM
    const regex =
      /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4} (?:[01][0-9]|2[0-3]):[0-5][0-9]$/;
    return regex.test(dateTimeString);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);

    // Vérifie si la date entrée est valide
    if (!validateDate(newValue)) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleKeyDown = (event) => {
    // Vérifie si l'utilisateur a appuyé sur Entrée
    if (event.key === "Enter" && !error && onChange) {
      onChange(value, fieldName);
    }
  };

  const formattedDateTime = formatDateTime(value);
  const isPlaceholder = formattedDateTime === "JJ/MM/AAAA HH:MM";

  return (
    <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
      <InputLabel htmlFor={fieldName} shrink>
        {inputLabel}
      </InputLabel>
      <Input
        id={fieldName}
        value={formattedDateTime ?? ""}
        readOnly={readOnly}
        onChange={readOnly ? undefined : handleChange}
        onKeyDown={readOnly ? undefined : handleKeyDown}
        sx={{
          color: customizedColor
            ? "#F3420A"
            : isPlaceholder
            ? "gray"
            : theme.palette.primary.main,
          fontWeight: 560,
          background: !readOnly ? theme.palette.primary.editableBackground : "",
          borderBottom: !readOnly
            ? `2px solid ${theme.palette.primary.borderBottom}`
            : "",
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: "none", // Empêche la ligne noire lors du survol
          },
          "&:before, &:after": {
            borderBottom: !readOnly ? "none" : "",
          },
        }}
        placeholder="JJ/MM/AAAA HH:MM"
        endAdornment={
          customizedColor ? (
            <InputAdornment position="end">
              <ErrorIcon style={{ color: "#F3420A" }} />{" "}
              {/* Icône avec la même couleur que le texte */}
            </InputAdornment>
          ) : null
        }
      />
      {error && (
        <FormHelperText error={true}>
          Le format doit être JJ/MM/AAAA HH:MM
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default DateTimeField;
