import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  CardHeader,
  IconButton,
  InputLabel,
  useTheme,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getTime } from "../../../utils/dateUtils";
import CustomTextField from "../../layout/CustomTextField";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import KeyboardCommandKeyRoundedIcon from "@mui/icons-material/KeyboardCommandKeyRounded";
import CodeRoundedIcon from "@mui/icons-material/CodeRounded";
import CustomTooltip from "../../common/renderers/CustomTooltip";

const Enedis = ({ data }) => {
  const theme = useTheme();
  const [peakHours, setPeakHours] = useState([]);

  //Extract future off peak hours
  function extractOffPeakHours() {
    if (data?.futures_off_peak === null) {
      return null;
    }
    const timePattern = /(\d{1,2}H\d{2})-(\d{1,2}H\d{2})/g;
    let match;
    const timeRanges = [];
    // Extract time ranges from the string
    while ((match = timePattern.exec(data?.futures_off_peak)) !== null) {
      const [startTime, endTime] = match.slice(1, 3);
      timeRanges.push({
        start: startTime.replace("H", ":"),
        end: endTime.replace("H", ":"),
      });
    }
    return timeRanges;
  }

  useEffect(() => {
    setPeakHours(extractOffPeakHours());
  }, [data]);

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#b8c494",
          color: theme.palette.primary.main,
          height: "50px",
        }}
        title="Informations ENEDIS"
        titleTypographyProps={{ variant: "h6", fontSize: "0.875rem" }}
        action={
          <Box display="flex" alignItems="center">
            <CustomTooltip title="Rafraichir les commandes ENEDIS" arrow>
              <IconButton aria-label="cached" variant="circled">
                <CachedRoundedIcon
                  sx={{ color: theme.palette.primary.main, fontSize: "1rem" }}
                />
              </IconButton>
            </CustomTooltip>
            <CustomTooltip title="Lancer une commande ENEDIS" arrow>
              <IconButton variant="circled" aria-label="refresh">
                <KeyboardCommandKeyRoundedIcon
                  sx={{ color: theme.palette.primary.main, fontSize: "1rem" }}
                />
              </IconButton>
            </CustomTooltip>
            <CustomTooltip title="Afficher l'interface de debug">
              <IconButton aria-label="code" variant="circled">
                <CodeRoundedIcon
                  sx={{ color: theme.palette.primary.main, fontSize: "1rem" }}
                />
              </IconButton>
            </CustomTooltip>
          </Box>
        }
      />
      <CardContent>
        {/* Section des informations contractuelles */}
        <Typography
          variant="h7"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold" }}
        >
          Informations contractuelles
        </Typography>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 3,
          }}
        >
          <CustomTextField
            id="contractual_state"
            value={data["contractual_state"] ?? ""}
            inputLabel="État Enedis"
            sx={{ flex: 1 }}
          />
          <CustomTextField
            id="enedis_segment"
            value={data["enedis_segment"] ?? ""}
            inputLabel="Segment"
            sx={{ flex: 1 }}
          />
          <CustomTextField
            id="counting_type"
            value={data["counting_type"] ?? ""}
            inputLabel="Compteur"
            sx={{ flex: 1 }}
          />
          <CustomTextField
            id="meter_serial_number"
            value={data["meter_serial_number"] ?? ""}
            inputLabel="N° de série"
            sx={{ flex: 1 }}
          />
        </Box>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 3,
          }}
        >
          <CustomTextField
            id="max-subscribed-power"
            value={data["max_subscribed_power"] ?? ""}
            inputLabel="Puissance Souscrite (kVa)"
            sx={{ flex: 1 }}
          />
          <CustomTextField
            id="pricing-option"
            value={data["pricing_option"] ?? ""}
            inputLabel="Option tarifaire"
            sx={{ flex: 1 }}
          />
          <CustomTextField
            id="injection-power-presence"
            value={data?.injection_power_presence ? "Yes" : "No"}
            inputLabel="Injection sur les 3 dernières années"
            sx={{ flex: 2 }}
          />
        </Box>
        <Box mt={2}>
          <CustomTextField
            id="calendar"
            value={data?.frn_calendar_label ?? ""}
            inputLabel="Calendrier"
          />
        </Box>
        <Box sx={{ mt: 2 }} />
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink sx={{ marginBottom: "10px" }}>
            Plage(s) d'heures creuses actuelles
          </InputLabel>
          <Box sx={{ marginTop: "25px" }}>
            {" "}
            {data.off_peak_start?.map((startTime, index) => (
              <List
                sx={{ padding: 0, paddingLeft: "10px", marginBottom: "5px" }}
                key={index}
              >
                <ListItem disableGutters sx={{ padding: 0 }}>
                  <ListItemText
                    primary={`• ${getTime(startTime)} - ${getTime(
                      data.off_peak_end[index]
                    )}`}
                    primaryTypographyProps={{
                      fontSize: "0.875rem",
                      lineHeight: "1.2",
                      fontWeight: 600,
                    }}
                    sx={{ margin: 0, color: theme.palette.primary.main }}
                  />
                </ListItem>
              </List>
            ))}
          </Box>
        </FormControl>
        {peakHours ? (
          <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <InfoOutlinedIcon
              sx={{
                color: "#b8c494",
                fontSize: "2rem",
                paddingTop: "10px",
              }}
            />
            <FormControl variant="standard" fullWidth>
              {peakHours.length > 0 && (
                <InputLabel shrink sx={{ marginBottom: "10px" }}>
                  Futures plages d'heures creuses
                  <Box
                    component="span"
                    sx={{
                      fontSize: "1.2rem",
                      lineHeight: "1.2",
                      fontWeight: 600,
                      color: "primary.main", // To resemble the color in ListItemText
                      marginLeft: "10px", // Add spacing similar to ListItemText
                    }}
                  >
                    {peakHours.map((hours, index) => (
                      <span key={index}>
                        {hours.start} à {hours.end}
                        {index !== peakHours.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </Box>
                </InputLabel>
              )}
            </FormControl>
          </Box>
        ) : (
          ""
        )}
        <hr style={{ backgroundColor: "#b8c494" }} /> <Box sx={{ mt: 2 }} />
        <CustomTextField
          id="formule"
          value={data?.routing_tariff_formula_label ?? ""}
          inputLabel="Formule"
          sx={{ mb: 1 }}
          multiline={true}
        />
      </CardContent>
    </Card>
  );
};

export default Enedis;
