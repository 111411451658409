import React from "react";
import Toolbar from "@mui/material/Toolbar";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Box } from "@mui/material";

const AlertingWall = () => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          gap: 2,
          mb: 2,
        }}
      >
        <h3>Alerting Wall</h3>
      </Box>
    </Box>
  );
};

export default AlertingWall;
