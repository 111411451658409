import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  CardHeader,
  InputLabel,
  useTheme,
  IconButton,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { SwitchWithLabel } from "../../layout/CustomizedSwitch";
import DateField from "../../layout/DateField";
import SelectField from "../../layout/SelectField";
import DateTimeField from "../../layout/DateTimeField";
import CustomTextField from "../../layout/CustomTextField";
import ErrorIcon from "@mui/icons-material/Error";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import { updateActivePdl } from "../../../services/pdlService";

const GeneralInfos = ({
  pdlId,
  pdlData,
  featureData,
  poolData,
  onActivationChange,
}) => {
  const theme = useTheme();
  const [pdlInfos, setPdlInfos] = useState([]);
  const [featureInfos, setFeatureInfos] = useState([]);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [selectedGroupe, setSelectedGroupe] = useState([]);
  const [selectedHeatingMode, setSelectedHeatingMode] = useState("");
  const [selectedPools, setSelectedPools] = useState([]);

  const [groupeOptions /*setGroupeOptions*/] = useState([]);
  const [pools /*setPools*/] = useState([]);
  const heatingModes = ["ELECTRIC", "GAS", "COLLECTIVE", "FUEL", "OTHER"];

  // Fonction pour vérifier si une date (en chaîne) est inférieure à il y a deux jours
  const isDateOlderThanTwoDays = (dateString) => {
    // Convertir la chaîne de date en objet Date
    const dateToCheck = new Date(dateString);

    // Obtenir la date d'aujourd'hui
    const today = new Date();

    // Soustraire 2 jours de la date d'aujourd'hui
    const twoDaysAgo = new Date(today);
    twoDaysAgo.setDate(today.getDate() - 2);

    // Vérifier si la date à vérifier est plus ancienne que la date d'il y a deux jours
    return dateToCheck < twoDaysAgo;
  };

  // Utilisation d'un effet pour initialiser les données lorsque le composant est monté
  useEffect(() => {
    if (Object.keys(pdlData).length !== 0) {
      setPdlInfos(pdlData);
      setIsActive(pdlData.active);
    }
    if (Object.keys(featureData).length !== 0) {
      setFeatureInfos(featureData);
      setSelectedGroupe(featureData.available_power_group);
      setSelectedHeatingMode(featureData.heating_mode);
    }
    if (poolData.pools) {
      setSelectedPools(
        poolData.pools?.map((element) => {
          return element.name;
        })
      );
    }
  }, [pdlData, featureData, poolData]);

  const handleChange = (newValue, fieldName) => {
    if (fieldName === "heating-mode") {
      setSelectedHeatingMode(newValue);
    }
  };

  const handleChangePools = (event) => {
    const { value } = event.target;
    setSelectedHeatingMode(
      typeof value === "string" ? value.split(",") : value
    );
  };

  // Copie l'ID du PDL dans le presse-papiers et affiche le tooltip
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(pdlId)
      .then(() => {
        setOpenTooltip(true);
        setTimeout(() => setOpenTooltip(false), 1000);
        console.log(`Copied to clipboard: ${pdlId}`);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  useEffect(() => {
    if (Object.keys(pdlData).length !== 0) {
      setIsActive(pdlData.active); //update or set the PDL status Active or Not
    }
  }, [pdlData]);

  // Gère le basculement de l'état actif/inactif du PDL
  const handleToggleActive = async (event) => {
    const isChecked = event.target.checked;
    setIsActive(isChecked);
    const result = await updateActivePdl(pdlData.id, isChecked);

    if (onActivationChange) {
      onActivationChange(isChecked); // Update DetailPdl status
    }
  };

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#f6c64f",
          color: theme.palette.primary.main,
          height: "50px",
        }}
        title="Informations générales"
        titleTypographyProps={{ variant: "h6", fontSize: "0.875rem" }}
        action={
          <>
            <Tooltip
              title={pdlId?.replaceAll("-", " ") + " copié!"}
              open={openTooltip}
              disableHoverListener
              placement="top"
            >
              <IconButton
                aria-label="email"
                variant="circled"
                onClick={handleCopyToClipboard}
              >
                <AlternateEmailIcon
                  sx={{ color: theme.palette.primary.main, fontSize: "1rem" }}
                />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <CardContent>
        {/* Section "Affectation" */}
        <Typography
          gutterBottom
          variant="h7"
          component="div"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold" }}
        >
          Affectation
        </Typography>
        <Box display="flex" mt={2} justifyContent="space-between">
          <DateField
            fieldName="creation-date"
            value={pdlInfos?.creation_date ?? ""}
            inputLabel="Date de création"
            sx={{ marginRight: 2, flex: 3 }}
          />
          <DateField
            fieldName="resiliation-date"
            value={pdlInfos?.resiliation_date ?? ""}
            inputLabel="Date de résiliation"
            sx={{ marginRight: 2, flex: 3 }}
          />
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="last-contract-update" shrink>
              {isActive ? "Activé" : "Désactivé"}
            </InputLabel>
            <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <SwitchWithLabel
                    checked={isActive}
                    onChange={handleToggleActive} // Call the function to handle the toggle
                    sx={{ m: 1 }}
                  />
                }
                label=""
              />
            </Box>
          </FormControl>
        </Box>
        {/* Sélection des pools et groupes */}
        <Box display="flex" mt={2} justifyContent="space-between">
          <SelectField
            label="Pool"
            fieldName="pool"
            options={pools}
            value={selectedPools}
            onChange={handleChangePools}
            multiple={true}
            readOnly={false}
          />
          <SelectField
            label="Groupe"
            fieldName="groupe"
            options={groupeOptions}
            value={selectedGroupe}
            onChange={handleChange("groupe", selectedGroupe)}
            multiple={false}
            readOnly={false}
          />
        </Box>
        {/* Certification et date de certification */}
        <Box display="flex" mt={2} justifyContent="space-between">
          <CustomTextField
            fieldName="certification"
            value={
              pdlInfos["edc_certification_date"]
                ? "Site certifié"
                : "Site non certifié"
            }
            inputLabel="Certification"
            sx={{
              marginRight: 2,
              flex: 1,
            }}
            fullWidth={false}
            IconToShow={
              <ErrorIcon sx={{ color: theme.palette.primary.warning }} />
            }
          />
          <DateField
            fieldName="certification-date"
            value={pdlInfos?.edc_certification_date ?? ""}
            inputLabel="Date de certification"
            readOnly={false}
            onChange={(newValue, fieldName) =>
              handleChange(newValue, fieldName)
            }
            sx={{ paddingTop: "16px", marginRight: 2, flex: 1 }}
          />
        </Box>
        {/* Section des dernières activités */}
        <Box sx={{ mt: 4 }} />
        <Typography
          gutterBottom
          variant="h7"
          component="div"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold" }}
        >
          Dernières activités
        </Typography>
        <Box display="flex" mt={2} justifyContent="space-between">
          <DateTimeField
            fieldName="cdc-date"
            value={featureInfos.off_peak_last_update_date ?? ""}
            inputLabel="Dernière modification HPHC"
            readOnly={true}
            onChange={(newValue, fieldName) => console.log(newValue, fieldName)}
          />
          <DateTimeField
            fieldName="last-ingestion-date"
            value={pdlInfos?.last_cdc_ingestion_date ?? ""}
            inputLabel="Dernière ingestion R50"
            readOnly={true}
            onChange={(newValue, fieldName) => console.log(newValue, fieldName)}
            customizedColor={isDateOlderThanTwoDays(
              pdlInfos?.last_cdc_ingestion_date
            )}
          />
        </Box>
        {/* Section des informations de logement */}
        <Box sx={{ mt: 4 }} />
        <Typography
          gutterBottom
          variant="h7"
          component="div"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold" }}
        >
          Informations logement
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          mt={2}
          alignItems="center"
        >
          <SelectField
            label="Mode de chauffage"
            fieldName="heating-mode"
            options={heatingModes}
            value={selectedHeatingMode}
            onChange={(event) =>
              handleChange(event.target.value, "heating-mode")
            }
            multiple={false}
            readOnly={false}
          />
          <CustomTextField
            fieldName="area"
            value={pdlInfos["area"]}
            inputLabel="Superficie (m²)"
            readOnly={false}
            onChange={(newValue, fieldName) =>
              handleChange(fieldName, newValue)
            }
            sx={{ marginRight: 2, flex: 1 }}
            IconToShow={
              <BorderColorIcon
                sx={{ color: theme.palette.primary.borderBottom }}
              />
            }
          />
        </Box>
        <Box mt={2}>
          <CustomTextField
            fieldName="address"
            value={pdlInfos["address"]}
            inputLabel="Adresse"
          />
        </Box>
        <Box display="flex" mt={2} justifyContent="space-between">
          <CustomTextField
            fieldName="nearest_weather_station"
            value={pdlInfos["nearest_weather_station"]}
            inputLabel="Station météorologique la plus proche"
            readOnly={false}
            onChange={(newValue, fieldName) =>
              handleChange(fieldName, newValue)
            }
            sx={{ marginRight: 2, flex: 1 }}
            IconToShow={
              <WbSunnyOutlinedIcon
                sx={{ color: theme.palette.primary.borderBottom }}
              />
            }
          />
          <CustomTextField
            fieldName="address_insee_code"
            value={pdlInfos["address_insee_code"]}
            inputLabel="Code INSEE"
            sx={{ marginRight: 2, flex: 1 }}
          />
        </Box>
        <Box display="flex" mt={2} justifyContent="space-between">
          <CustomTextField
            fieldName="longitude"
            value={pdlInfos["longitude"]}
            inputLabel="Longitude (°)"
            sx={{ marginRight: 2, flex: 1 }}
          />
          <CustomTextField
            fieldName="latitude"
            value={pdlInfos["latitude"]}
            inputLabel="Latitude"
            sx={{ marginRight: 2, flex: 1 }}
          />
          <CustomTextField
            fieldName="climatic_zone"
            value={pdlInfos["climatic_zone"]}
            inputLabel="Zone climatique"
            sx={{ marginRight: 2, flex: 2 }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default GeneralInfos;
