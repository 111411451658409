import { InputLabel, Input, FormControl, FormHelperText } from "@mui/material";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";

const DateField = ({
  fieldName,
  value: initialValue,
  inputLabel,
  readOnly = true,
  onChange,
  sx,
}) => {
  const [value, setValue] = useState(initialValue ?? "");
  const [error, setError] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setValue(initialValue ?? "");
  }, [initialValue]);

  // Fonction pour formater la date vers JJ/MM/AAAA
  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return `${String(date.getDate()).padStart(2, "0")}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${date.getFullYear()}`;
  };

  const formattedDate = formatDate(value);

  // Fonction de validation du format JJ/MM/AAAA
  const validateDate = (dateString) => {
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    return regex.test(dateString);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);

    // Vérifie si la date entrée est valide
    if (!validateDate(newValue)) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleKeyDown = (event) => {
    // Vérifie si l'utilisateur a appuyé sur Entrée
    if (event.key === "Enter" && !error && onChange) {
      onChange(value, fieldName);
    }
  };

  return (
    <FormControl variant="standard" sx={sx} error={error}>
      <InputLabel sx={sx} htmlFor={fieldName} shrink>
        {inputLabel}
      </InputLabel>
      <Input
        id={fieldName}
        value={readOnly ? formattedDate ?? "" : value}
        readOnly={readOnly}
        onChange={readOnly ? undefined : handleChange}
        onKeyDown={readOnly ? undefined : handleKeyDown}
        sx={{
          color: !value ? "gray" : theme.palette.primary.main,
          fontWeight: 560,
          background: !readOnly ? theme.palette.primary.editableBackground : "",
          borderBottom: !readOnly
            ? `2px solid ${theme.palette.primary.borderBottom}`
            : "",
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: "none", // Prevent black line on hover
          },
          "&:before, &:after": {
            borderBottom: !readOnly ? "none" : "",
          },
        }}
        placeholder="JJ/MM/AAAA"
      />
      {error && (
        <FormHelperText error={true}>
          Le format doit être JJ/MM/AAAA
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default DateField;
