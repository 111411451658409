import React, { useState, useEffect, useCallback } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Tooltip,
  Checkbox,
  TextField,
  FormControlLabel,
  Select,
  MenuItem,
  useTheme,
  Switch,
} from "@mui/material";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  getPDLById,
  getPdlsByCustomer,
  updateActivePdl,
} from "../../../services/pdlService";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//import ActionAlert from "../../layout/ActionAlert";
import GeneralInfos from "./GeneralInfos";
import ProviderInfos from "./ProviderInfos";
import Effacement from "./Effacement";
import Configuration from "./Configuration";
import Logs from "./Logs";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
//import { getTime } from "../../../utils/dateUtils";
import RefreshIcon from "@mui/icons-material/Refresh";

const DetailDevice = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the ID from the URL
  const theme = useTheme();
  const [activeDevice, setActiveDevice] = useState(false);
  const [dataByCustomer, setDataByCustomer] = useState([]);
  const [data, setData] = useState({});
  const [DeviceDescription, setDeviceDescription] = useState("");
  const [DeviceId, setDeviceId] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setDeviceId(id);
  }, [id]);

  // Function to fetch detail Device data
  const onGetDeviceByIdData = useCallback(async (device_id) => {
    try {
      const dataByDevice = await getPDLById(device_id);
      setData(dataByDevice);
      setActiveDevice(dataByDevice["active"]);
      const result = await getPdlsByCustomer(dataByDevice["customer_id"]);
      setDataByCustomer(result);

      // Find the element in dataByCustomer that matches Device_name_nbdevices from dataByDevice
      const matchingElement = result.find(
        (element) =>
          element.Device_name_nbdevices === dataByDevice.Device_name_nbdevices
      );

      // Set the matching element as the default value
      if (matchingElement) {
        setDeviceDescription(matchingElement);
      }
    } catch (error) {
      console.error("Failed to fetch Device data:", error);
    }
  }, []);

  // useEffect hook to fetch data on component mount
  // useEffect(() => {
  //   onGetDeviceByIdData(id);
  // }, [onGetDeviceByIdData, id]);

  const handleChange = (event) => {
    const selectedElement = event.target.value;
    setDeviceDescription(selectedElement);
    navigate(`/detaildevice/${selectedElement.device_id}`);
    // onGetDeviceByIdData(selectedElement.device_id);
  };

  const OnEnableDisableDevice = useCallback(async () => {
    setOpen(true);
    setActiveDevice(!activeDevice);
    const result = await updateActivePdl(DeviceId, !activeDevice);
  });

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3, backgroundColor: "#f3f3f3" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Box>
              <Grid item mb={4} display="flex" alignItems="center">
                {/* Customer Name Field */}
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={
                    Object.keys(data).length !== 0
                      ? data["customer_last_name"]
                      : "Customer non spécifié"
                  }
                  InputProps={{
                    readOnly: true,
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "2px",
                        borderColor: theme.palette.primary.main,
                        borderRadius: "25px",
                      },
                      "& .MuiInputBase-input": {
                        color: theme.palette.primary.main,
                      },
                    },
                  }}
                  sx={{ mr: 2, width: "220px" }}
                />

                {/* Device Select Field */}
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={DeviceDescription || ""}
                  label="PDL Description"
                  onChange={handleChange}
                  sx={{
                    mr: 2,
                    width: "260px",
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "25px",
                    color: "white",
                    ".MuiSelect-icon": {
                      color: "white",
                    },
                  }}
                >
                  {dataByCustomer.map((element, index) => (
                    <MenuItem key={index} value={element}>
                      {element.Device_name_nbdevices}
                    </MenuItem>
                  ))}
                </Select>
                <Typography
                  sx={{ ml: "auto", color: theme.palette.primary.main }}
                >
                  Dernière mise à jour le 02/02/2023 à 16:17
                  {/* {getTime(pdlData.update_date)} */}
                </Typography>
                <RefreshIcon
                  onClick={() => window.location.reload()}
                  style={{ cursor: "pointer" }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Box display="flex" alignItems="center">
                  {/* PDL Information */}
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color={theme.palette.primary.main}
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    PDL&nbsp;
                    <FiberManualRecordRoundedIcon
                      sx={{
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                        fontSize: "10px",
                        margin: "0 8px",
                      }}
                    />
                    {Object.keys(data).length !== 0
                      ? data["Device_name"]
                      : "Nom d'appareil non spécifié"}
                  </Typography>

                  {/* Separator */}
                  <Tooltip title="Arrow separator">
                    <ArrowForwardIosIcon
                      sx={{
                        ml: 1,
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                        fontSize: "16px",
                        margin: "0 8px",
                      }}
                    />
                  </Tooltip>

                  {/* Device Type */}
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color={theme.palette.primary.main}
                    sx={{ fontWeight: "bold" }}
                  >
                    {Object.keys(data).length !== 0
                      ? data["device_type"]
                      : "Type d'appareil non spécifié"}
                  </Typography>

                  {/* Active Switch */}
                  <Box ml={4} display="flex" alignItems="center">
                    <Typography
                      sx={{ color: theme.palette.primary.main, mr: 1 }}
                    >
                      {activeDevice ? "Activé" : "Désactivé"}
                    </Typography>
                    <Tooltip title={activeDevice ? "Désactiver" : "Activer"}>
                      <PowerSettingsNewIcon
                        onClick={OnEnableDisableDevice}
                        style={{
                          cursor: "pointer",
                          color: activeDevice ? "green" : "red",
                          fontSize: "24px",
                        }}
                      />
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ height: 20 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <GeneralInfos deviceId={id} deviceData={data} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ mb: 2 }}>
            <ProviderInfos providerData={data} />
          </Box>
          <Logs data={data} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ mb: 2 }}>
            <Effacement data={data} />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Configuration data={data} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailDevice;
