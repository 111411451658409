import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#24395E", // Principal color
      activated: "#14AE5C",
      deactivated: "#7e7b7a",
      editableBackground: "#eaf9f9",
      borderBottom: "#4EBCC3",
      warning: "#EC221F",
      warningBackground: "#fcf4f2",
      paperBackground: "#f8f4f4",
    },
    secondary: {
      main: "#E6007E", // Secondary color
    },
    other: {
      main: "#4EBCC3",
    },
  },
  components: {
    MuiIconButton: {
      variants: [
        {
          props: { variant: "circled" },
          style: {
            padding: "8px",
            borderRadius: "50%",
            border: `2px solid #24395E`,
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "8px",
          },
        },
      ],
    },
  },
});

export default theme;
