import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  CardHeader,
  Input,
  InputLabel,
  IconButton,
  useTheme,
  FormControlLabel,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CircleIcon from "@mui/icons-material/Circle";
import {
  SwitchWithLabel,
  CheckmarkSwitchWithLabel,
} from "../../layout/CustomizedSwitch";
import { updateActiveDevice } from "../../../services/deviceService";
import DateTimeField from "../../layout/DateTimeField";
import SelectField from "../../layout/SelectField";
import CustomTextField from "../../layout/CustomTextField";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CustomTooltip from "../../common/renderers/CustomTooltip";
import CodeRoundedIcon from "@mui/icons-material/CodeRounded";

const GeneralInfos = ({ deviceId, deviceData }) => {
  const theme = useTheme();
  const [isActive, setIsActive] = useState(false);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState("");
  const [openTooltip, setOpenTooltip] = useState({
    email: false,
    copy: false,
  });
  const mockDeviceData = {
    id: "1234-abcd-5678-efgh",
    last_update_contract: "2023-10-22T10:45:00",
    last_update_hphc: "2023-10-21T11:30:00",
    creation_date: "2023-01-15T09:00:00",
    localisation: "Paris",
    marque: "Symphonics",
    model: "X1000",
    product_name: "SuperDevice",
    master: "Yes",
    n_serie: "SN1234567890",
    provider: "DeviceCorp",
    "parent-device": "ParentDevice",
  };
  // Gère le basculement de l'état actif/inactif du device
  const handleToggleActive = async (event) => {
    const isChecked = event.target.checked;
    setIsActive(isChecked);
    const result = await updateActiveDevice(mockDeviceData.id, isChecked);
  };

  const handleChange = () => {};

  const handleCopyToClipboard = (valueToCopy, tooltipKey) => {
    navigator.clipboard
      .writeText(valueToCopy)
      .then(() => {
        setOpenTooltip((prev) => ({ ...prev, [tooltipKey]: true }));
        setTimeout(() => {
          setOpenTooltip((prev) => ({ ...prev, [tooltipKey]: false }));
        }, 1000);
        console.log(`Copied to clipboard: ${valueToCopy}`);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return `${String(date.getDate()).padStart(2, "0")}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${date.getFullYear()} ${String(
      date.getHours()
    ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
  };

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#edc647ff",
          color: theme.palette.primary.main,
          height: "50px",
        }}
        title="Informations générales - Symphonics"
        titleTypographyProps={{ variant: "h6", fontSize: "0.875rem" }}
        action={
          <>
            <Tooltip
              title={mockDeviceData.id?.replaceAll("-", " ") + " copié!"}
              open={openTooltip.email}
              disableHoverListener
              placement="top"
            >
              <Box display="flex" alignItems="center">
                <IconButton
                  aria-label="email"
                  variant="circled"
                  onClick={() => handleCopyToClipboard(deviceId, "email")}
                >
                  <AlternateEmailIcon
                    sx={{ color: theme.palette.primary.main, fontSize: "1rem" }}
                  />
                </IconButton>
                <CustomTooltip title="Afficher l'interface de log 'additionnal data provider'">
                  <IconButton aria-label="code" variant="circled">
                    <CodeRoundedIcon
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: "1rem",
                      }}
                    />
                  </IconButton>
                </CustomTooltip>
              </Box>
            </Tooltip>
          </>
        }
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="subtitle1"
          component="div"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold" }}
        >
          Données d'activités
        </Typography>

        <Box display="flex" mt={2} justifyContent="space-between">
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="statut" shrink>
              Statut
            </InputLabel>
            <Input
              disableUnderline
              startAdornment={
                <InputAdornment position="start">
                  {/* Cercle Vert */}
                  <CircleIcon
                    sx={{
                      color: true ? theme.palette.primary.activated : "#D32F2F",
                      fontSize: 30,
                    }}
                  />
                </InputAdornment>
              }
              value={true ? "ON" : "OFF"}
              readOnly
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 560,
                backgroundColor: theme.palette.primary.editableBackground, // Couleur de fond légèrement bleutée
                borderBottom: `2px solid ${theme.palette.primary.borderBottom}`,
                "&:hover:not(.Mui-disabled):before": {
                  borderBottom: "none",
                },
              }}
            />
            {/* Date de dernier changement affichée en dessous */}
            <Typography variant="caption" sx={{ marginTop: 1 }}>
              Der. chang. le
            </Typography>
          </FormControl>

          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="last-contract-update" shrink>
              Plan de chauffe
            </InputLabel>
            <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
              <FormControlLabel
                control={<SwitchWithLabel sx={{ m: 1 }} defaultChecked />}
                label=""
              />
            </Box>
          </FormControl>

          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="last-contract-update" shrink>
              Plan d'effacement
            </InputLabel>
            <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
              <FormControlLabel
                control={<SwitchWithLabel sx={{ m: 1 }} defaultChecked />}
                label=""
              />
            </Box>
          </FormControl>

          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="last-contract-update" shrink>
              {isActive ? "Activé" : "Désactivé"}
            </InputLabel>
            <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <SwitchWithLabel
                    checked={isActive}
                    onChange={handleToggleActive}
                    sx={{ m: 1 }}
                  />
                }
                label=""
              />
            </Box>
          </FormControl>
        </Box>

        <Box display="flex" mt={2} justifyContent="space-between">
          <FormControl
            variant="standard"
            sx={{ marginRight: 2, flex: 1, alignItems: "center" }}
          >
            <InputLabel htmlFor="score-connexion" shrink>
              Score de fiabilité de connexion (%)
            </InputLabel>
            <Input
              id="score-connexion"
              value={80} // Valeur du score
              readOnly
              disableUnderline
              sx={{
                color: "green", // Couleur du score
                fontWeight: "bold",
                textAlign: "center",
              }}
            />
            <Box
              sx={{
                width: "100%",
                borderBottom: `2px solid ${theme.palette.primary.main}`,
              }}
            />
          </FormControl>

          <FormControl
            variant="standard"
            sx={{ marginRight: 2, flex: 1, alignItems: "center" }}
          >
            <InputLabel htmlFor="score-connexion" shrink>
              Score de fiabilité lors des plans (%)
            </InputLabel>
            <Input
              id="score-plan"
              value={50} // Valeur du score
              readOnly
              disableUnderline
              sx={{
                color: "orange", // Couleur du score
                fontWeight: "bold",
                textAlign: "center",
              }}
            />
            <Box
              sx={{
                width: "100%",
                borderBottom: `2px solid ${theme.palette.primary.main}`,
              }}
            />
          </FormControl>
        </Box>
        <Box sx={{ mt: 4 }} />
        <Typography
          gutterBottom
          variant="subtitle1"
          component="div"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold", mb: 3 }} // Adding margin-bottom for spacing after the title
        >
          Caractéristiques
        </Typography>

        <Box display="flex" mt={2} justifyContent="space-between" mb={3}>
          <DateTimeField
            fieldName="creation-date"
            value={mockDeviceData?.creation_date ?? ""}
            inputLabel="Date de création"
            sx={{ marginRight: 2, flex: 3 }}
          />
          <DateTimeField
            fieldName="last_update"
            value={mockDeviceData?.last_update ?? ""}
            inputLabel="Dernière update"
            sx={{ marginRight: 2, flex: 3 }}
          />
        </Box>

        <Box display="flex" mt={2} justifyContent="space-between">
          <SelectField
            label="Type d'appareil"
            fieldName="device-type"
            options={deviceTypes}
            value={selectedDeviceType}
            onChange={handleChange}
            multiple={false}
            readOnly={false}
          />
          <CustomTextField
            fieldName="localisation"
            value={mockDeviceData["localisation"]}
            inputLabel="Localisation"
            readOnly={false}
            onChange={(newValue, fieldName) =>
              handleChange(fieldName, newValue)
            }
            sx={{ marginTop: 1, marginRight: 2, flex: 1 }}
            IconToShow={
              <BorderColorIcon
                sx={{ color: theme.palette.primary.borderBottom }}
              />
            }
          />
        </Box>
        <Box display="flex" mt={2} justifyContent="space-between">
          <CustomTextField
            fieldName="marque"
            value={mockDeviceData["marque"]}
            inputLabel="Marque"
            onChange={(newValue, fieldName) =>
              handleChange(fieldName, newValue)
            }
            sx={{ marginRight: 2, flex: 1 }}
          />
          <CustomTextField
            fieldName="model"
            value={mockDeviceData["model"]}
            inputLabel="Modèle"
            onChange={(newValue, fieldName) =>
              handleChange(fieldName, newValue)
            }
            sx={{ marginRight: 2, flex: 1 }}
          />
        </Box>

        <Box display="flex" mt={2} justifyContent="space-between">
          <CustomTextField
            fieldName="product_name"
            value={mockDeviceData["product_name"]}
            inputLabel="Product name"
            onChange={(newValue, fieldName) =>
              handleChange(fieldName, newValue)
            }
            sx={{ marginRight: 2, flex: 1 }}
          />
          <CustomTextField
            fieldName="master"
            value={mockDeviceData["master"]}
            inputLabel="Master"
            onChange={(newValue, fieldName) =>
              handleChange(fieldName, newValue)
            }
            sx={{ marginRight: 2, flex: 1 }}
          />
        </Box>

        <Box display="flex" mt={2} justifyContent="space-between">
          <CustomTextField
            fieldName="n_serie"
            value={mockDeviceData["n_serie"]}
            inputLabel="N° série"
            onChange={(newValue, fieldName) =>
              handleChange(fieldName, newValue)
            }
            sx={{ marginRight: 2, flex: 1 }}
            IconToShow={
              <Tooltip
                title="N° série copié!"
                open={openTooltip.copy}
                disableHoverListener
                placement="top"
              >
                <IconButton
                  aria-label="copy"
                  onClick={() =>
                    handleCopyToClipboard(mockDeviceData["n_serie"], "copy")
                  }
                >
                  <ContentCopyIcon
                    sx={{ color: theme.palette.primary.borderBottom }}
                  />
                </IconButton>
              </Tooltip>
            }
          />
          <CustomTextField
            fieldName="provider"
            value={mockDeviceData["provider"]}
            inputLabel="Nom du device (provider)"
            onChange={(newValue, fieldName) =>
              handleChange(fieldName, newValue)
            }
            sx={{ marginRight: 2, flex: 1 }}
          />
        </Box>

        <Box display="flex" mt={2} justifyContent="space-between">
          <CustomTextField
            fieldName="parent-device"
            value={mockDeviceData["parent-device"]}
            inputLabel="Parent device"
            onChange={(newValue, fieldName) =>
              handleChange(fieldName, newValue)
            }
            sx={{ marginRight: 2, flex: 1 }}
            IconToShow={
              <RemoveRedEyeIcon
                sx={{ color: theme.palette.primary.borderBottom }}
              />
            }
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default GeneralInfos;
