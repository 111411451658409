import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardHeader,
  useTheme,
  Paper,
  Grid,
  FormControl,
  IconButton,
} from "@mui/material";
import CustomTooltip from "../../common/renderers/CustomTooltip";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";

const Configuration = ({ data }) => {
  const theme = useTheme();

  const features = [
    { feature: "label name de l'item de la feature", value: "1 kW" },
    { feature: "Feature list energy_counter_total", value: "1 kW" },
    { feature: "Feature list energy_counter_total", value: "1 kW" },
    { feature: "Feature list energy_counter_total", value: "1 kW" },
    { feature: "Feature list energy_counter_total", value: "1 kW" },
    { feature: "Feature list energy_counter_total", value: "1 kW" },
  ];

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#40bcc4",
          color: theme.palette.primary.main,
          height: 50,
        }}
        title="Configuration actuelle"
        titleTypographyProps={{ variant: "h6", fontSize: "0.875rem" }}
        action={
          <CustomTooltip title="Rafraichir les commandes ENEDIS" arrow>
            <IconButton aria-label="cached" variant="circled">
              <CachedRoundedIcon
                sx={{ color: theme.palette.primary.main, fontSize: "1rem" }}
              />
            </IconButton>
          </CustomTooltip>
        }
      />
      <CardContent>
        <FormControl variant="standard" fullWidth>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              backgroundColor: theme.palette.primary.paperBackground,
              borderRadius: 2,
              mt: 2,
            }}
          >
            {/* Header */}
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item xs={8}>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color={theme.palette.primary.main}
                >
                  Items features
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color={theme.palette.primary.main}
                  align="right"
                >
                  Valeur
                </Typography>
              </Grid>
            </Grid>

            {/* Data Rows */}
            {features.map((item, index) => (
              <Grid container key={index} spacing={2} sx={{ mb: 0.5 }}>
                <Grid item xs={8}>
                  <Typography
                    variant="body2"
                    color={theme.palette.primary.main}
                  >
                    {item.feature}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    color={theme.palette.primary.main}
                    align="right"
                  >
                    {item.value}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Paper>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default Configuration;
