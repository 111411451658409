import React from "react";
import { Button, IconButton, Box } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useTheme } from "@mui/material/styles";

const ExportButtons = () => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {/* Export CSV Button */}
      <Button
        variant="contained"
        startIcon={<DownloadIcon />}
        sx={{
          backgroundColor: theme.palette.primary.main,
          borderRadius: "20px",
          textTransform: "none",
          padding: "5px 20px",
          fontWeight: "bold",
          fontSize: "14px",
          "&:hover": {
            backgroundColor: "#1b2d5a",
          },
        }}
      >
        Exporter
      </Button>
      {/* IconButton with info */}
      <LogoutIcon
        sx={{
          color: theme.palette.primary.main,
          fontSize: "20px",
        }}
      />

      {/* IconButton with info icon */}

      <InfoOutlinedIcon
        sx={{
          color: "#556987",
          fontSize: "20px",
        }}
      />
    </Box>
  );
};

export default ExportButtons;
