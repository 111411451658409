import React, { useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";
import CustomTextField from "../../layout/CustomTextField";
import { SwitchWithLabel } from "../../layout/CustomizedSwitch";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import { formatToPhoneNum } from "../../../utils/numberUtils";
import ActionAlert from "../../layout/ActionAlert";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

const GeneralInfos = ({ customerData, onActivationChange, isActive }) => {
  console.log("GeneralInfos - customerData:", customerData);

  const theme = useTheme();
  const { customerId } = useParams(); // Get id from URL params
  const [open, setOpen] = useState(false);
  const [/*openTooltip,*/ setOpenTooltip] = useState(false);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };
  const handleChange = (newValue, fieldName) => {};
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(customerId)
      .then(() => {
        setOpenTooltip(true);
        setTimeout(() => setOpenTooltip(false), 1000);
        console.log(`Copied to clipboard: ${customerId}`);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleDownloadJson = () => {
    const jsonData = JSON.stringify(customerData, null, 2);
    const blob = new Blob([jsonData], { type: "application/json" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "customerData.json";
    link.click();
  };

  // Handler for toggling the activation state
  const handleToggleActive = (event) => {
    const isChecked = event.target.checked;
    console.log("Toggle switch changed to:", isChecked);
    if (onActivationChange) {
      onActivationChange(isChecked); // Notify DetailCustomer
    }
  };
  // Extract relevant customer details from API data
  const { nom, prenom, email, adresse, telephone_fixe, telephone_mobile } =
    customerData?.informations_generales || {};
  //const pdl = customerData?.informations_pdls?.[0]?.pdl || "N/A"; // Getting the PDL from the first PDL in the list

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#f6c64f",
          color: theme.palette.primary.main,
          height: "50px",
        }}
        title="Informations générales"
        titleTypographyProps={{ variant: "h6", fontSize: "0.875rem" }}
        action={
          <Box display="flex" alignItems="center">
            {/* First Button: Copy ID with Tooltip */}
            <Tooltip title="Copy ID">
              <IconButton
                size="large"
                aria-label="ID"
                variant="circled"
                onClick={handleCopyToClipboard}
              >
                <AlternateEmailIcon
                  sx={{ color: theme.palette.primary.main, fontSize: "1rem" }}
                />
              </IconButton>
            </Tooltip>

            {/* Second Button: Download JSON with Tooltip */}
            <Tooltip title="Afficher l'interface de log crm_initialization_step">
              <IconButton
                aria-label="Download JSON"
                sx={{
                  padding: "0",
                  alignSelf: "center",
                  //marginLeft: "8px",
                }}
                onClick={handleDownloadJson}
                variant="circled"
              >
                <DownloadIcon
                  sx={{ color: theme.palette.primary.main, fontSize: "1rem" }}
                />
              </IconButton>
            </Tooltip>

            <ActionAlert
              open={open}
              setOpen={setOpen}
              message={"ID copied to clipboard!"}
            />
          </Box>
        }
      />

      <Box sx={{ p: 2 }}>
        <Typography
          gutterBottom
          variant="h7"
          component="div"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold" }}
        >
          Informations d'identité
        </Typography>

        <Box display="flex" mt={2} justifyContent="space-between">
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="last-contract-update" shrink>
              Etat d'initialisation
            </InputLabel>
            <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={customerData?.initialization_done || false}
                    disabled
                  />
                }
                label={customerData?.initialization_done ? "DONE" : "PENDING"}
                labelPlacement="end"
              />
            </Box>
          </FormControl>
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="last-contract-update" shrink>
              {isActive ? "Activé" : "Désactivé"}
            </InputLabel>
            <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <SwitchWithLabel
                    checked={isActive}
                    onChange={handleToggleActive}
                    sx={{ m: 1 }}
                  />
                }
                label=""
              />
            </Box>
          </FormControl>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <CustomTextField
                inputLabel="Segment"
                value={customerData?.segment || "N/A"}
                readOnly={true}
                onChange={(newValue, fieldName) =>
                  handleChange(fieldName, newValue)
                }
                sx={{
                  backgroundColor: theme.palette.background.default,
                  flex: 1,
                  borderBottom: 2,
                  borderColor: theme.palette.primary.main,
                }}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <CustomTextField
                inputLabel="Nom customer parent"
                value={customerData?.parent_name || "N/A"}
                readOnly={true}
                onChange={(newValue, fieldName) =>
                  handleChange(fieldName, newValue)
                }
                sx={{
                  backgroundColor: theme.palette.background.default,
                  flex: 1,
                  borderBottom: 2,
                  borderColor: theme.palette.primary.main,
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ flex: 0.2 }}>
              <CustomTextField
                inputLabel="Civilité"
                value={customerData?.civilite || "N/A"}
                fullWidth
                readOnly={true}
                onChange={(newValue, fieldName) =>
                  handleChange(fieldName, newValue)
                }
                sx={{
                  backgroundColor: theme.palette.background.default,
                  flex: 1,
                  borderBottom: 2,
                  borderColor: theme.palette.primary.main,
                }}
              />
            </Box>
            <Box sx={{ flex: 0.5 }}>
              <CustomTextField
                inputLabel="Nom"
                value={nom || "N/A"}
                readOnly={true}
                onChange={(newValue, fieldName) =>
                  handleChange(fieldName, newValue)
                }
                sx={{
                  backgroundColor: theme.palette.background.default,
                  flex: 1,
                  borderBottom: 2,
                  borderColor: theme.palette.primary.main,
                }}
              />
            </Box>
            <Box sx={{ flex: 0.5 }}>
              <CustomTextField
                inputLabel="Prénom"
                fieldName="prénom"
                value={prenom || "N/A"}
                readOnly={true}
                onChange={(newValue, fieldName) =>
                  handleChange(fieldName, newValue)
                }
                sx={{
                  backgroundColor: theme.palette.background.default,
                  flex: 1,
                  borderBottom: 2,
                  borderColor: theme.palette.primary.main,
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box sx={{ mt: 2 }}>
          <CustomTextField
            inputLabel="Société"
            fieldName="Nom de la société"
            value={customerData?.nom_societe || "N/A"}
            readOnly={false}
            onChange={(newValue, fieldName) =>
              handleChange(fieldName, newValue)
            }
            sx={{ marginRight: 2, flex: 1 }}
          />
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography
            gutterBottom
            variant="h7"
            component="div"
            color={theme.palette.primary.main}
            sx={{ fontWeight: "bold" }}
          >
            Adresse Client
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CustomTextField
                inputLabel="Adresse"
                value={adresse || "N/A"}
                InputProps={{ readOnly: true }}
                onChange={(newValue, fieldName) =>
                  handleChange(fieldName, newValue)
                }
                sx={{
                  backgroundColor: theme.palette.background.default,
                  flex: 1,
                  borderBottom: 2,
                  borderColor: theme.palette.primary.main,
                }}
              />
              <IconButton
                onClick={() => handleCopy(adresse || "N/A")}
                sx={{
                  color: theme.palette.primary.borderBottom,
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CustomTextField
                inputLabel="Adresse normalisée + INSEE"
                value={customerData?.adresse_normalisee || "N/A"}
                InputProps={{ readOnly: true }}
                onChange={(newValue, fieldName) =>
                  handleChange(fieldName, newValue)
                }
                sx={{
                  backgroundColor: theme.palette.background.default,
                  flex: 1,
                  borderBottom: 2,
                  borderColor: theme.palette.primary.main,
                }}
              />
              <IconButton
                onClick={() => handleCopy(adresse || "N/A")}
                sx={{
                  color: theme.palette.primary.borderBottom,
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography
            gutterBottom
            variant="h7"
            component="div"
            color={theme.palette.primary.main}
            sx={{ fontWeight: "bold" }}
          >
            Informations de contact
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CustomTextField
                inputLabel="Mail"
                value={email || "N/A"}
                readOnly={false}
                onChange={(newValue, fieldName) =>
                  handleChange(fieldName, newValue)
                }
                sx={{
                  backgroundColor: theme.palette.background.default,
                  flex: 1,
                  borderColor: theme.palette.primary.borderBottom,
                }}
              />
              <IconButton
                onClick={() => handleCopy(email || "N/A")}
                sx={{
                  color: theme.palette.primary.borderBottom,
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CustomTextField
                inputLabel="Telephone fixe"
                value={formatToPhoneNum(telephone_fixe || "N/A")}
                readOnly={false}
                onChange={(newValue, fieldName) =>
                  handleChange(fieldName, newValue)
                }
                sx={{
                  backgroundColor: theme.palette.background.default,
                  flex: 1,
                  borderColor: theme.palette.primary.borderBottom,
                }}
              />
              <IconButton
                onClick={() => handleCopy(telephone_fixe || "N/A")}
                sx={{
                  color: theme.palette.primary.borderBottom,
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CustomTextField
                inputLabel="Telephone mobile"
                value={formatToPhoneNum(telephone_mobile || "N/A")}
                readOnly={false}
                onChange={(newValue, fieldName) =>
                  handleChange(fieldName, newValue)
                }
                sx={{
                  backgroundColor: theme.palette.background.default,
                  flex: 1,
                  borderColor: theme.palette.primary.borderBottom,
                }}
              />
              <IconButton
                onClick={() => handleCopy(telephone_mobile || "N/A")}
                sx={{
                  color: theme.palette.primary.borderBottom,
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default GeneralInfos;
