import React from "react";
import { Switch, Box } from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material";

const LabeledSwitch = styled(Switch)(({ theme, ownerState }) => ({
  width: 80,
  height: 41,
  padding: 8,
  "& .MuiSwitch-switchBase": {
    padding: 10,
    "&.Mui-checked": {
      transform: "translateX(36px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.activated,
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 20,
    height: 20,
  },
  "& .MuiSwitch-track": {
    borderRadius: 20,
    backgroundColor: theme.palette.primary.deactivated,
    opacity: 1,
    position: "relative",
    transition: theme.transitions.create(["background-color"], {
      duration: 200,
    }),
    "&:before": {
      content: ownerState.checked ? '"ON"' : '""',
      position: "absolute",
      left: 10,
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: 12,
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    "&:after": {
      content: !ownerState.checked ? '"OFF"' : '""',
      position: "absolute",
      right: 10,
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: 12,
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
  },
}));

const SwitchWithLabel = ({ checked, onChange }) => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center">
      <LabeledSwitch
        checked={checked}
        onChange={onChange}
        theme={theme}
        ownerState={{ checked }}
      />
    </Box>
  );
};

const CheckmarkSwitch = styled(Switch)(({ theme }) => ({
  width: 70,
  height: 44,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    transform: "translateX(4px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(26px)",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#4caf50" : "#4caf50",
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#f0e9ff",
    width: 26,
    height: 26,
    position: "relative", // Position relative pour le pseudo-élément
    "&:before": {
      content: "'✔'", // Symbole de check en Unicode
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      fontSize: 18,
      color: "#2c065d",
      display: "none", // Par défaut, caché
    },
  },
  "& .Mui-checked .MuiSwitch-thumb:before": {
    display: "block", // Affiche l'icône de check quand activé
  },
  "& .MuiSwitch-track": {
    borderRadius: 34 / 2,
    backgroundColor: "#ccc",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const CheckmarkSwitchWithLabel = ({ checked, onChange }) => {
  const theme = useTheme();
  return (
    <Box display="flex" alignItems="center">
      <CheckmarkSwitch checked={checked} onChange={onChange} theme={theme} />
    </Box>
  );
};

export { CheckmarkSwitchWithLabel, SwitchWithLabel };
