import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Typography,
  Box,
  Tooltip,
  Button,
  Select,
  MenuItem,
  useTheme,
  TextField,
  /*IconButton,*/
  InputAdornment,
} from "@mui/material";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useParams, useNavigate } from "react-router-dom";
import { getPDLById /*updateActivePdl*/ } from "../../../services/pdlService";
import GeneralInfos from "./GeneralInfos";
import Enedis from "./Enedis";
import Effacement from "./Effacement";
import AssociatedDevices from "./AssociatedDevices";
import ActionAlert from "../../layout/ActionAlert";
import { formatToPdlNumber } from "../../../utils/numberUtils";
import { getTime, getOnlyDate } from "../../../utils/dateUtils";
import ExportButtons from "../../layout/ExportButtons";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import StopCircleIcon from "@mui/icons-material/StopCircle";

const DetailPdl = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [updateDate, setUpdateDate] = useState("");
  const { id } = useParams();

  const [activePdl, setActivePdl] = useState(false);
  const [effacementState /*setEffacementState*/] = useState(false);
  const [dataByCustomer /*setDataByCustomer*/] = useState([]);
  const [data, setData] = useState({});
  const [pdlData, setPdlData] = useState({});
  const [customerData, setCustomerData] = useState({});
  const [featureData, setFeatureData] = useState({});
  const [poolData, setPoolData] = useState({});
  const [devices, setDevices] = useState({});
  const [pdlsByCustomer /*setPdslByCustomer*/] = useState([]);
  const [open, setOpen] = useState(false);

  // Fetch PDL Data by ID
  const fetchPdlData = useCallback(async (pdl_id) => {
    try {
      const data = await getPDLById(pdl_id);
      setData(data);
      setPdlData(data.pdl || {});
      setCustomerData(data.customer || {});
      setFeatureData(data.feature || {});
      setDevices(data.devices || {});
      setPoolData(data.pools || {});
      setActivePdl(data.pdl?.active || false);
    } catch (error) {
      console.error("Failed to fetch PDL data:", error);
    }
  }, []);

  useEffect(() => {
    if (id) fetchPdlData(id);
  }, [id, fetchPdlData]);

  useEffect(() => {
    setUpdateDate(getOnlyDate(pdlData.update_date));
  }, [pdlData]);

  const handleChange = (event) => {
    const selectedElement = event.target.value;
    navigate(`/detailpdl/${selectedElement.pdl_id}`);
    fetchPdlData(selectedElement.pdl_id);
  };

  const navigateToCustomer = () => {
    navigate(`/detailCustomer/${customerData.id}`);
  };

  const handlePdlActivationChange = (isActive) => {
    setActivePdl(isActive); //update the pdl status from general infos
  };

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3, backgroundColor: "#f3f3f3" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="outlined"
              onClick={navigateToCustomer}
              sx={{
                mr: 2,
                height: "55px",
                padding: "8px 16px",
                whiteSpace: "nowrap",
                fontSize: "20px",
                borderRadius: "25px", // Rounded border
                border: `2px solid ${theme.palette.primary.main}`, // Border color
                backgroundColor: "white",
              }}
            >
              {Object.keys(data).length !== 0
                ? customerData["last_name"]
                : "Nom du client non spécifié"}
            </Button>
            {pdlsByCustomer.length <= 1 ? (
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={
                  (formatToPdlNumber(pdlData["name"]) || "Nom non spécifié") +
                  " (" +
                  (devices?.devices_informations?.nb_devices ?? "0") +
                  ")"
                }
                InputProps={{
                  readOnly: true,
                  sx: {
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    textAlign: "center",
                    borderRadius: "25px", // Rounded border
                  },
                  inputProps: {
                    style: { textAlign: "center", color: "white" }, // Fix text color inside input
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {effacementState ? (
                        <PlayCircleFilledWhiteIcon style={{ color: "green" }} />
                      ) : (
                        <StopCircleIcon style={{ color: "grey" }} />
                      )}
                    </InputAdornment>
                  ),
                }}
                sx={{ mr: 2, width: "300px" }}
              />
            ) : (
              // <TextField
              //   id="outlined-basic"
              //   variant="outlined"
              //   value={pdlData["name"]}
              //   InputProps={{
              //     readOnly: true,
              //     sx: {
              //       "& .MuiOutlinedInput-notchedOutline": {
              //         borderWidth: "2px",
              //         borderColor: theme.palette.primary.main,
              //       },
              //       "& .MuiInputBase-input": {
              //         color: theme.palette.primary.main,
              //       },
              //     },
              //   }}
              //   sx={{ mr: 2, width: "300px" }}
              // />
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={formatToPdlNumber(pdlData["name"]) || ""}
                label="PDL Description"
                onChange={handleChange}
                sx={{
                  mr: 2,
                  width: "300px",
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  ".MuiSelect-icon": {
                    color: "white",
                  },
                }}
              >
                {dataByCustomer.map((element, index) => (
                  <MenuItem key={index} value={element}>
                    {element.pdl_name_nbdevices}
                  </MenuItem>
                ))}
              </Select>
            )}
            <Typography sx={{ ml: "auto", color: theme.palette.primary.main }}>
              Dernière mise à jour le {updateDate} à{" "}
              {getTime(pdlData.update_date)}
            </Typography>
            <RefreshIcon
              onClick={() => window.location.reload()}
              style={{ cursor: "pointer" }}
            />
          </Box>
        </Grid>

        {/* PDL Header */}
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                }}
              >
                PDL
              </Typography>
              <Tooltip title="Annotation point">
                <FiberManualRecordRoundedIcon
                  sx={{
                    ml: 1,
                    cursor: "pointer",
                    color: theme.palette.primary.main,
                    fontSize: "small",
                  }}
                />
              </Tooltip>
              <Typography
                variant="h5"
                sx={{ ml: 2, color: theme.palette.primary.main }}
              >
                {pdlData?.name
                  ? formatToPdlNumber(pdlData.name)
                  : "Nom du pdl non spécifié"}
              </Typography>
              <Box display="flex" alignItems="center" sx={{ ml: 2 }}>
                <Typography sx={{ color: theme.palette.primary.main, mr: 1 }}>
                  {activePdl ? "Activé" : "Désactivé"}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    border: `2px solid ${activePdl ? "green" : "red"}`,
                  }}
                >
                  <PowerSettingsNewIcon
                    style={{ color: activePdl ? "green" : "red" }}
                  />
                </Box>
              </Box>
            </Box>
            <ExportButtons />
          </Box>

          <ActionAlert
            open={open}
            setOpen={setOpen}
            message={`Le PDL ${formatToPdlNumber(pdlData.name)} a été ${
              activePdl ? "activé" : "désactivé"
            }!`}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 2 }} />

      {/* Additional Information */}
      <Box sx={{ display: "flex", gap: 2 }}>
        {/* Colonne de gauche */}
        <Box sx={{ flex: 1 }}>
          <GeneralInfos
            pdlId={pdlData.id}
            pdlData={pdlData}
            featureData={featureData}
            poolData={poolData}
            onActivationChange={handlePdlActivationChange} // Apply the immediate change
          />
        </Box>

        {/* Colonne centrale */}
        <Box sx={{ flex: 1 }}>
          <Box sx={{ mb: 2 }}>
            <Enedis data={featureData} />
          </Box>
          <Box>
            <Effacement data={featureData} />
          </Box>
        </Box>

        {/* Colonne de droite */}
        <Box sx={{ flex: 0.7 }}>
          <AssociatedDevices data={devices} pdl_id={pdlData.id} />
        </Box>
      </Box>
    </Box>
  );
};

export default DetailPdl;
